import { Link } from '@abyss/web/ui/Link';
import isNil from 'lodash/isNil';
import { ReactNode } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { CustomAttributesBlock } from '../Utils/adobeTrackUtils/adobeTrackUtils';

type AnalyticsInfo = {
  destinationUrl?: string | null;
  location: string;
  name?: string | null;
  type?: string | null;
  customAttributesBlock?: CustomAttributesBlock | null;
};

type Props = {
  after?: ReactNode;
  before?: ReactNode;
  analyticsInfo?: AnalyticsInfo;
  'aria-label'?: string;
  children?: ReactNode;
  css?: any;
  'data-testid': string;
  'data-auto-testid': string;
  fontWeight?: string;
  hideIcon?: Boolean;
  href?: string | null;
  isStandardAnchor?: Boolean;
  onClick?: Function;
  size?: number | string;
  variant?: string;
  isDisabled?: Boolean;
  openNewWindow?: boolean;
};

export const LinkWithTracking = ({
  after,
  before,
  analyticsInfo,
  'aria-label': ariaLabel = '',
  children,
  css,
  'data-testid': testId = '',
  'data-auto-testid': dataAutoTestId = '',
  fontWeight,
  hideIcon = false,
  href = null,
  isStandardAnchor,
  onClick = () => {},
  size,
  variant,
  isDisabled = false,
  openNewWindow,
}: Props) => {
  const onClickWithTracking = (e) => {
    if (!isNil(onClick)) onClick(e);
    if (analyticsInfo?.location === 'body:Browse for care') return;
    adobeLinkTrackEvent({
      name: analyticsInfo?.name || testId,
      location: analyticsInfo?.location || testId,
      type: analyticsInfo?.type,
      destinationUrl: analyticsInfo?.destinationUrl || href,
      customAttributesBlock: analyticsInfo?.customAttributesBlock,
    });
  };

  return (
    <Link
      after={after}
      aria-label={ariaLabel}
      before={before}
      css={css}
      data-auto-testid={dataAutoTestId}
      data-testid={testId}
      fontWeight={fontWeight}
      hideIcon={hideIcon}
      href={href}
      isDisabled={isDisabled}
      isStandardAnchor={isStandardAnchor}
      onClick={onClickWithTracking}
      openNewWindow={openNewWindow}
      size={size}
      variant={variant}
    >
      {children}
    </Link>
  );
};
