import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';

export const HeaderContainer = styled('div', {
  backgroundColor: '$bgProviderDetailsColor',
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '$sm',
  '@media (max-width: 1248px)': {
    paddingLeft: '$lg',
    paddingRight: '$lg',
  },
  '@screen < $sm': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export const HeaderFlex = styled(Flex, {
  '&.abyss-flex-root': {
    marginTop: '0px',
  },
  '@screen < $sm': {
    paddingLeft: '$md',
    paddingRight: '$md',
  },
});

export const HeaderContentWrapper = styled('div', {});

export const HeaderPageDetails = styled('div', {
  backgroundColor: '$bgProviderDetailsColor',
});

export const NavAndShareButton = styled(Button, {
  '&.abyss-button-root': {
    color: '$primary1',
    height: 'unset',
    padding: '0',
    '@screen < $sm': { fontSize: '12.64px', lineHeight: '16px' },
  },
});
export const VoiceOnlyText = styled('p', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});

export const HeaderIcon = styled(IconMaterial, {
  '&.abyss-icon-material': {
    marginRight: '2px',
  },
});
export const DetailsSectionWrap = styled('div', {
  display: 'flex',
  marginTop: '8px',
  '@screen < $lg': {
    marginTop: '$sm',
  },
  '@screen < $sm': {
    marginTop: '$md',
    paddingLeft: '16px',
    paddingRight: '32px',
  },
});

export const DetailsHeaderSection = styled('div', {
  '@screen < $sm': {
    paddingLeft: '16px',
  },
});

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    display: 'flex',
    borderRadius: '100px',
    border: '1px solid $primary1',
    background: '$white',
    alignItems: 'flex-start',
    padding: '4px',
    position: 'relative',
    top: '-82px',
    left: '60px',
    zindex: 1,
  },
};

export const DetailsSubSection = styled('div', {
  width: '100%',
  marginLeft: '$md',
});

export const ProviderTitle = styled(Heading, {
  '@screen < $sm': {
    '&.abyss-heading-root': {
      fontWeight: '$bold',
      fontSize: '$lg !important',
      lineHeight: '24px !important',
    },
  },
  '&.abyss-heading-root': {
    fontWeight: '$bold',
    fontSize: '22.78px',
    lineHeight: '32px',
  },
});

export const ProviderSubtitleSection = styled(Flex, {
  justifyContent: 'space-between',
  marginTop: '4px',
  '@screen < $sm': {
    flexDirection: 'column',
  },
});

export const ProviderSubtitle = styled(Text, {
  marginRight: '24px',
});

export const ProviderIndicatorSection = styled('div', {
  display: 'inline-flex',
  marginTop: '12px',
  '@screen >= $md': {
    justifyContent: 'flex-start',
  },
  '@screen >= $lg': {
    justifyContent: 'flex-end',
  },
  '@screen < $sm': {
    justifyContent: 'space-between',
    marginTop: '4px',
  },
});

export const ProviderRatingStar = styled(IconMaterial, {
  '&.abyss-icon-material': {
    verticalAlign: 'sub',
  },
});

export const ProviderRatingText = styled(Text, {
  marginLeft: '5px',
  marginTop: '2px',
});
export const ProviderRatingsText = styled(Text, {
  marginLeft: '5px',
  '@screen < $sm': {
    '&.abyss-text-root': {
      fontSize: '$sm',
      marginTop: '-0.1px',
    },
  },
  marginTop: '4px',
});

export const ProviderIndicator = styled(Text, {
  marginLeft: '5px',
});

export const ProviderUpdatedSubsection = styled(Flex, {
  alignItems: 'start',
  color: '$primary2',
  fontSize: '12.64px',
  fontWeight: '$medium',
  lineHeight: '16px',
  marginTop: '$md',
  padding: '0',
});

export const ProviderUpdatedContainer = styled('div', {
  '@screen < $sm': { marginTop: '2px' },
});

export const SuggestEditLink = styled(Link, {
  '&.abyss-link-root': {
    color: '$primary2',
    fontSize: '12.64px',
    fontWeight: '$bold',
    lineHeight: '16px',
    textDecoration: 'underline',
    marginRight: '$sm',
  },
});

export const YourPCPMessageBox = styled(Alert, {
  '&.abyss-alert-root': {
    '.abyss-alert-container': {
      border: 'none',
      background: '$tint2',
      minHeight: 'unset',
      padding: '9px $sm',
      '.abyss-alert-icon-container': {
        marginRight: '$sm',
      },
      '.abyss-alert-title': {
        fontSize: '12.64px',
        color: '$primary1',
        marginRight: 0,
      },
    },
    '.abyss-alert-action-button': {
      border: 'none',
      color: '$interactive1',
      background: 'none',
      fontSize: '12.64px',
      fontWeight: '$bold',
      height: 'unset',
      padding: '0px',
      margin: '0px',
      '.abyss-icon-material': { marginRight: '5px' },
    },
  },
});

export const ChoosePCPButtonContainer = styled(Button, {
  '&.abyss-button-root': {
    backgroundColor: '#D14600',
    fontWeight: '$semibold',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    '.abyss-insert-element-before': {
      paddingRight: '5px !important',
    },
  },
});
export const InteractSection = styled('div', {
  paddingTop: '$lg',
  paddingBottom: '$lg',
  a: {
    padding: '$sm $lg $sm $md',
  },
  '@screen < $sm': {
    paddingTop: '$md',
    paddingBottom: '$md',
    a: {
      height: '32px',
      display: 'flex',
      padding: '6px $lg 6px $md',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
    },
  },
  display: 'flex',
  gap: '8px',
  overflow: 'auto',
});

export const InteractButton = styled(Button, {
  '&.abyss-button-root': {
    whiteSpace: 'nowrap',
    padding: '$sm $md $sm $md',
    '@screen < $sm': {
      overflow: 'visible',
      height: '32px',
      display: 'flex',
      padding: '6px $md 6px $md',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
    },
  },
  '.abyss-insert-element-root': {
    fontWeight: '$semibold',
    color: '$primary1 !important',
    '.abyss-insert-element-after': {
      paddingLeft: '8px',
    },
  },
  '.abyss-insert-element-before': {
    paddingRight: '5px !important',
  },
});

export const AvatarDetailsPage = styled('div', {
  width: '84px',
  height: '84px',
  minWidth: '84px',
  minHeight: '84px',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const DetailTierInfoBox = styled(Box, {
  display: 'flex',
  padding: '2px 8px',
  alignItems: 'center',
  marginTop: '$md',
  marginBottom: '$md',
  gap: '4px',
  borderRadius: '4px',
  '&.abyss-box-root': {
    backgroundColor: '#F2F8E6',
    padding: '$sm',
    minWidth: '128px',
    width: 'fit-content',
    height: '22px',
  },
});

export const DetailTierCardText = styled(Text, {
  '&.abyss-text-root': {
    color: '#4B4D4F',
    fontSize: '$sm',
    fontWeight: '$bold',
    lineHeight: '18px',
  },
});

export const LayoutContainer = styled(Layout.Group, {
  gap: '18px',
  margin: '0px, 0px , 8px, 0px',
});
export const YourPCPContainer = styled('div', {
  maxWidth: '343px',
  width: '343px',
  margin: '$lg 0 0 0',
  height: '34px',
  borderRadius: '4px',
  padding: '8px',
  gap: '32px',
  backgroundColor: '$tint2',
  display: 'flex',
  '@screen < $sm': {
    margin: '$md 0 0 $md',
  },
});

export const YourPCPMessageDetailsContainer = styled('div', {
  lineHeight: '16px',
  gap: '8px',
  display: 'flex',
});

export const chipStyle = {
  overflow: 'visible',
  '.abyss-insert-element-root': {
    backgroundColor: '$white',
    border: '1px solid $primary1',
    borderRadius: '50px',
    height: '40px',
    padding: '0 20px 0 20px',
    color: '$primary1',
    fontWeight: '$semibold',
  },
  '.abyss-insert-element-before': {
    paddingRight: '5px !important',
  },
};

export const CostIndicatorIconStyle = styled('div', {
  '.abyss-icon-material': {
    height: '24px',
    width: '24px',
    '@screen < $sm': {
      height: '20px',
      width: '20px',
    },
  },
});

export const MarginStyle = styled('div', {
  marginRight: '24px',
});

export const BadgeContainer = styled('div', {
  marginBottom: '4px',
  '@screen < $sm': {
    marginBottom: '-8px',
  },
});

export const PopoverWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});
export const PCPAlertMessage = {
  '.abyss-alert-container': {
    borderColor: '$white',
    minHeight: '36px !important',
    borderWidth: '0px !important',
    border: 0,
    padding: '8px 8px 8px 0px',
  },
  '.abyss-alert-icon-container': {
    alignItems: 'center',
    height: '20px',
    width: '20px',
  },
  '.abyss-alert-header-text-container': {
    lineHeight: '20px',
    fontSize: '16px',
    fontWeight: '700',
  },
};

export const PCPAlertMessageMobile = {
  '.abyss-alert-container': {
    borderColor: '$white',
    minHeight: '36px !important',
    borderWidth: '0px !important',
    border: 0,
    padding: '0px',
  },
  '.abyss-alert-icon-container': {
    alignItems: 'center',
    height: '16.67px',
    width: '16.67px',
  },
  '.abyss-alert-title': {
    fontSize: '14px',
    alignItems: 'center',
  },
};
export const AlertMessage = {
  backgroundColor: '#EDF3FB',
};

export const PCPNotChosenCSS = {
  display: 'flex',
  marginLeft: 'auto',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingRight: 'calc(50% - 600px)',
  paddingLeft: 'calc(50% - 600px)',
  border: '1px solid #196ECF',
  borderRadius: '4px',
  backgroundColor: '#EDF3FB',
  '@screen < $xl': {
    padding: '8px 8px 8px 24px',
  },
  '@screen < $lg': {
    padding: '8px 8px 8px 12px',
    minHeight: '56px',
    alignItems: 'start',
  },
};

export const PCPNotChosenContentCSS = {
  gap: '16px',
  display: 'flex',
  alignItems: 'center',
  '@screen < $lg': {
    flexDirection: 'column',
    width: 'auto',
    gap: '4px',
    alignItems: 'start',
  },
};

export const StyledPCPNotChosenHeader = styled('div', {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#323333',
  '@screen < $lg': {
    fontSize: '$md',
  },
});

export const StyledPCPNotChosenText = styled('div', {
  fontSize: '14px',
  fontWeight: '400',
  font: 'Enterprise Sans VF',
  color: '#323334',
  lineHeight: '18px',
  '@screen < $lg': {
    fontSize: '$sm',
  },
});

export const PCPNotChosenButtonCSS = {
  'abyss-button-root': {
    height: '20px',
    paddingLeft: '0x',
    paddingRight: '0x',
    fontSize: '14px',
    fontWeight: '700',
    font: 'Enterprise Sans VF',
    fontColor: '#196ECF',
    lineHeight: '18px',
    '@screen < $lg': {
      fontSize: '$sm',
    },
  },
};

export const AlertStyling = {
  '.abyss-alert-title': {
    lineHeight: '20px',
    marginTop: '2px',
    color: '#323333',
  },
  '.abyss-alert-container': {
    height: '52px',
    borderWidth: '1px 0px 1px 0px',
  },
  '.abyss-alert-header-container': {
    marginLeft: 'auto',
    maxWidth: '1200px',
    marginRight: 'auto',
    alignItems: 'center',
  },
  '.abyss-icon-symbol ': {
    fontSize: '20px !important',
    paddingTop: '2px',
  },
  '.abyss-alert-children-container': {
    paddingTop: '2px',
    lineHeight: '18px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#323333',
  },
  '.abyss-alert-icon-container': {
    marginRight: '4px',
  },
  '.abyss-alert-header-text-container': {
    flexDirection: 'row',
    '@media (max-width: 593px)': {
      flexDirection: 'column',
    },
    '@screen < $sm': {
      flexDirection: 'column',
    },
  },
};

export const StyledViewIdBannerBox = styled(Box, {
  border: 'solid $interactive1',
  borderRadius: '4px',
  borderWidth: '1px 1px 1px 4px',
  margin: '-16px 0 8px 0',
});

export const StyledViewIdBannerFlex = styled(Flex, {
  '>button': {
    padding: '0 16px',
    marginLeft: '8px',
    width: '131px',
  },
  '>span': {
    margin: '0 8px',
  },
  '>svg': {
    marginRight: '-4px',
  },

  '@screen < $sm': {
    button: {
      marginLeft: '24px',
    },
    '>span': {
      margin: '2px 0',
    },
    '>span:nth-child(3)': {
      marginLeft: '24px',
    },
    '>svg': {
      marginRight: '4px',
    },
  },
});
