import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Pagination } from '@abyss/web/ui/Pagination';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { getLanguage } from '../../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../../../hooks/useLagoon';
import { usePhysicianDirectory } from '../../../../hooks/usePhysicianDirectory';
import { ProviderType } from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { getMemberHealthCoverageType } from '../../../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getMemberPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  PhysicianDirectorySortOrder,
  ReverseCoverageTypesCodes,
} from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { phoneOnly, tabletOnly } from '../../../ConstantsStyles';
import { getGeoLocationFromStorage } from '../../../PSXHeader/SearchBar/utils';
import { convertProviderTypeToAdobeType } from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import { PhysicianDirectoryInterface } from '../helper';
import { matchSearchTextWithPhysician } from '../PhysicianDirectory';
import { NoProviderText, SectionContainer } from './SpecialitySearch.styled';

type Props = {
  speciality: any;
  providerId: string;
  providerType: string;
};

const SpecialityDirectory = ({
  speciality,
  providerId,
  providerType,
}: Props) => {
  const { t } = useTranslation();
  const coverageTypeData = useCoverageType();
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerType),
      indicator: '',
    },
  });
  const pageSize = 21;
  const [physicians, getPhysicians] = usePhysicianDirectory({});
  const { navigate } = useRouter();
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const { chipValue, coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      chipValue: state.chipValue,
    }))
  );
  const [totalPhysicanCount, setTotalPhysicanCount] = useState();
  const [providerNameToSearch] = useState('');
  const [cancelPrevCalls, setCancelPrevCalls] = useState(true);
  const currentMember = getCurrentMember();
  const tablet = useMediaQuery(tabletOnly);
  const locale = getLanguage().code;
  const { gotoPage, state, previousPage, nextPage, ...paginationProps } =
    usePagination({
      pages: Math.ceil((totalPhysicanCount || 0) / pageSize),
    });
  const [sortOrder] = useState<PhysicianDirectorySortOrder>(
    PhysicianDirectorySortOrder.ASCENDING
  );
  const [physicianList, setPhysicianList] = useState(
    physicians?.data?.physicianDirectory?.physicianDirectoryList
  );
  const physicianDirectoryMapping: PhysicianDirectoryInterface[] = [];
  const mobileScreen = useMediaQuery(phoneOnly);
  const { currentPage, pageCount } = state;
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const tierBenefits = useLagoon('tier-benefit')();
  const { longitude, latitude } = getGeoLocationFromStorage();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate = getCoverageEffDateRange(
    currentMember,
    coverageType
  ).startDate;

  useEffect(() => {
    let medicalGroupAffiliationProviderId;
    let hospitalaffiliationproviderId;
    if (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) {
      if (providerId?.includes('GRP')) {
        medicalGroupAffiliationProviderId = providerId;
      }
      if (providerId?.includes('FAC')) {
        hospitalaffiliationproviderId = providerId;
      }
    } else {
      medicalGroupAffiliationProviderId =
        providerType !== ProviderType.ORGANIZATION ? providerId : null;
      hospitalaffiliationproviderId =
        providerType === ProviderType.ORGANIZATION ? providerId : null;
    }
    const medicalCoverage = getMemberHealthCoverageType(
      currentMember?.eligibility,
      CoverageTypesCodes.MEDICAL
    );
    setCancelPrevCalls(false);
    getPhysicians({
      variables: {
        latitude,
        longitude,
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getMemberPlanYear(isPreEffective, currentMemberEffectiveDate),
        memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        population: currentMember?.population,
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        policyID: currentMember?.policyNumber,
        providerName: '',
        providerType,
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          coverageType,
          featureFlags
        ),
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          providerType,
          uspTierOneFlag
        ),
        snpType:
          coverageType == ReverseCoverageTypesCodes.MEDICAL
            ? medicalCoverage?.snpType
            : '',
        membershipCategory,
        medicalGroupAffiliationProviderId,
        hospitalaffiliationproviderId,
        sortOrder,
        pageNumber: currentPage,
        pageSize,
        coverageType: coverageType || coverageTypeData,
        includeSpecialtyRollUpCodes: speciality?.codes?.join(','),
        locale,
      },
    });
  }, [speciality, currentPage, sortOrder, providerType]);
  const pdAllLinkTrack = (name: String) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY} pagination control:page ${currentPage}`,
      type: 'internal',
      destinationUrl: '',
    });
  };
  useEffect(() => {
    adobePageTrackEvent();
    pdAllLinkTrack(`results per page:${pageSize}`);
  }, []);
  useEffect(() => {
    adobePageTrackEvent();
    pdAllLinkTrack(`results per page:${pageSize}`);
  }, [pageSize]);

  const nextPageFunc = () => {
    pdAllLinkTrack('next page');
    nextPage();
  };
  const gotoPageFunc = (page) => {
    pdAllLinkTrack(`page ${page + 1}`);
    gotoPage(page);
  };
  const previousPageFunc = () => {
    pdAllLinkTrack('previous page');
    previousPage();
  };
  useEffect(() => {
    if (!cancelPrevCalls) {
      setPhysicianList(
        physicians?.data?.physicianDirectory?.physicianDirectoryList
      );
      setTotalPhysicanCount(physicians?.data?.physicianDirectory?.totalCount);
    }

    return () => {
      if (
        physicians?.data !== null &&
        physicians.providerNameToSearch === providerNameToSearch
      )
        setCancelPrevCalls(!cancelPrevCalls);
      else setCancelPrevCalls(false);
    };
  }, [physicians]);

  physicianList
    ?.filter((physician) => physician.providerName)
    ?.forEach((physician) => {
      const name = `${physician.providerName}, ${physician.primaryDegrees.join(
        ','
      )}`;
      physicianDirectoryMapping.push({
        providerName: name.replace(' ', ', '),
        providerId: physician.providerId,
        specialities: '',
      });
    });

  return (
    <SectionContainer>
      {physicians.isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="loading results"
          isLoading={physicians.isLoading}
          size="$sm"
        />
      ) : (
        <div style={{ width: '100%', padding: '0px $md' }}>
          {physicianDirectoryMapping && physicianDirectoryMapping.length ? (
            matchSearchTextWithPhysician(
              providerNameToSearch,
              physicianDirectoryMapping,
              navigate,
              mobileScreen,
              tablet,
              currentPage,
              setDetailsStore,
              { chipValue, coverageType }
            )
          ) : (
            <NoProviderText data-testid="no-provider-text">
              {t(
                'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.NO_PROVIDERS_IN_CATEGORY'
              )}
            </NoProviderText>
          )}
        </div>
      )}
      {pageCount > 1 && (
        <Pagination
          {...paginationProps}
          css={{
            'abyss-pagination-root': {
              marginTop: '$sm',
              '@screen < $sm': {
                marginTop: '$xs',
              },
            },
            'abyss-pagination-page-button': {
              minWidth: '20px',
            },
            'abyss-pagination-arrow': {
              paddingLeft: '4px',
              paddingRight: '4px',
            },
          }}
          customNextLabel={t('PAGINATION.NEXT')}
          customPreviousLabel={t('PAGINATION.PREVIOUS')}
          data-auto-testid="physician-directory-pagination"
          data-testid="physician-directory-pagination"
          gotoPage={gotoPageFunc}
          nextPage={nextPageFunc}
          pageSize={pageSize}
          previousPage={previousPageFunc}
        />
      )}
    </SectionContainer>
  );
};

export default SpecialityDirectory;
