import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../../../common/Constants';
import { phoneOnly } from '../../../../../../../common/ConstantsStyles';
import { ImageComponent } from '../../../../../../../common/ImageComponent';
import { handleLinkAndModalTrack } from '../../../../../../../common/Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../../../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useIsOverflow } from '../../../../../../../hooks/useIsOverflow';
import { CompareProvider } from '../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../models/ResponseHeaders';
import { useChipStore } from '../../../../../../../store/useChipStore';
import { ChipState } from '../../../../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../../../../store/useDetailsStore/detailsStore';
import { getFormattedAndValidatedPhoneNumber } from '../../../../../../../utils/phone.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../../../utils/providerNames.utils';
import {
  AvatarCompareCard,
  CompareCardCollapsedTextStyle,
  CompareCardExpandedStyle,
  CompareCardNibbleStyle,
  CompareCardTextStyle,
  CompareNameDetailsDiv,
  HighlightedBorderStyle,
  nullPhoneNumberStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../utility/compareDrawerConstants';
import { RemoveLink } from '../Buttons/RemoveLink';
import { ConfirmationModal } from '../ConfirmationModal';
import { MiniCompareCardAddress } from './CompareExpandedCard/Attributes/Location/MiniCompareCardAddress';
import { CompareExpandedCard } from './CompareExpandedCard/CompareExpandedCard';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  selectedProvider: CompareProvider;
  removeItem: (item: string) => void;
  isExpandedCardOpen?: boolean;
  isCompareViewOpen?: boolean;
  handleDetailsOnClick: (
    providerId: string,
    options?: Object,
    customAttributesBlock?: CustomAttributesBlock
  ) => void;
  setCardHeight: Function;
  index: number;
  headers: ResponseHeaders;
};

export const CompareCard = ({
  compareCheckboxes,
  selectedProvider,
  removeItem,
  isExpandedCardOpen,
  isCompareViewOpen,
  handleDetailsOnClick,
  headers,
  setCardHeight,
  index,
}: Props) => {
  const modalName = 'view-provider-modal-' + selectedProvider?.providerId;
  const modal = useOverlay(modalName);
  const { t } = useTranslation();
  const mobile = useMediaQuery(phoneOnly);
  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );
  const cardRef = useRef(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const { coverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );
  const providerType = selectedProvider?.providerType;
  const imgSrc = selectedProvider?.imageLocation;
  useEffect(() => {
    const height = cardRef?.current?.getBoundingClientRect()?.height;
    setCardHeight(height);
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const displayedPhoneNumber = getFormattedAndValidatedPhoneNumber(
    selectedProvider.phones?.phone?.[0]
  );
  const nameAttrs = getNameAttrs(selectedProvider);

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(selectedProvider, coverageType)
    ),
    providerType: convertProviderTypeToAdobeType(
      selectedProvider?.providerType
    ),
  };

  const handleProviderNameAndCarrotClick = () => {
    handleLinkAndModalTrack(
      PRACTITIONER_NAME_FOR_ANALYTICS,
      `modal:compare card:position ${index + 1}`,
      NEW_TAB_CONFIRMATION,
      undefined,
      customAttributesBlock
    );
    modal?.open();
  };
  const CompareNameDetails = () => (
    <Layout.Group>
      <CompareNameDetailsDiv
        cssProps={{ isOverflow }}
        data-auto-testid="provider-card-detail"
        data-testid="provider-card-detail"
        ref={containerRef}
      >
        <Text
          css={
            isExpandedCardOpen && isCompareViewOpen
              ? CompareCardTextStyle
              : CompareCardCollapsedTextStyle
          }
          data-auto-testid={`provider-card-detail-button-${selectedProvider.providerId}`}
          data-testid={`provider-card-detail-button-${selectedProvider.providerId}`}
          onClick={() => handleProviderNameAndCarrotClick()}
        >
          {parseProviderName(
            selectedProvider.providerName,
            selectedProvider.providerType,
            selectedProvider.primaryDegrees?.[0],
            nameAttrs
          )}
        </Text>
      </CompareNameDetailsDiv>

      {isExpandedCardOpen && isCompareViewOpen && (
        <IconMaterial
          data-auto-testid="arrow-right"
          data-testid="arrow-right"
          icon="keyboard_arrow_right"
          onClick={() => handleProviderNameAndCarrotClick()}
        />
      )}
    </Layout.Group>
  );

  const CompareText = () => (
    <Layout.Stack alignItems="left" space={0}>
      {isOverflow ? (
        <Tooltip
          align="center"
          asChild={false}
          content={parseProviderName(
            selectedProvider.providerName,
            selectedProvider.providerType,
            selectedProvider.primaryDegrees?.[0],
            nameAttrs
          )}
          position="top"
          positionOffset={8}
        >
          <CompareNameDetails />
        </Tooltip>
      ) : (
        <CompareNameDetails />
      )}

      <Text
        css={
          displayedPhoneNumber === NULL_ATTRIBUTE_STATE.PHONE_NUMBER &&
          nullPhoneNumberStyle
        }
        data-auto-testid="provider-phonenumber"
        data-testid="provider-phonenumber"
      >
        {isExpandedCardOpen && isCompareViewOpen && displayedPhoneNumber}
      </Text>
    </Layout.Stack>
  );

  const handleClickCb = () => {
    modal.close();
    const detailsStoreValues = {
      specialtySearch: selectedProvider?.speciality,
    };
    setDetailsStore(detailsStoreValues);
    handleDetailsOnClick(
      selectedProvider.providerId,
      { openInNewTab: true, providerType },
      customAttributesBlock
    );
  };

  return (
    <div ref={cardRef}>
      <Flex
        alignItems="center"
        data-auto-testid="compare-provider-card"
        data-testid="compare-provider-card"
        direction="column"
      >
        <Box
          align="start"
          css={
            isExpandedCardOpen && isCompareViewOpen
              ? CompareCardExpandedStyle
              : CompareCardNibbleStyle
          }
          data-auto-testid={`compare-card-${selectedProvider.providerId}`}
          data-testid={`compare-card-${selectedProvider.providerId}`}
          key={selectedProvider.providerId}
          style={
            selectedProvider.providerId === highlightId.providerId
              ? HighlightedBorderStyle
              : null
          }
          {...(isExpandedCardOpen &&
            isCompareViewOpen && {
              className: mobile
                ? 'compare-card-container-mobile'
                : 'compare-card-container',
            })}
          onClick={() => {
            setHighlightId({
              providerId: selectedProvider.providerId,
              from: '',
            });
          }}
        >
          <Layout.Stack alignItems="left">
            {mobile ? (
              <React.Fragment>
                <Layout.Group
                  alignItems="top"
                  alignLayout={isExpandedCardOpen ? 'left' : 'center'}
                  grow
                  space={46}
                >
                  <AvatarCompareCard>
                    {ImageComponent({ imgRef, providerType, imgSrc })}
                  </AvatarCompareCard>
                </Layout.Group>
                <CompareText />
              </React.Fragment>
            ) : (
              <Layout.Group>
                <AvatarCompareCard>
                  {ImageComponent({ imgRef, providerType, imgSrc })}
                </AvatarCompareCard>
                <Layout.Stack alignItems="flex-start">
                  <CompareText />
                  {isExpandedCardOpen && !isCompareViewOpen ? (
                    <MiniCompareCardAddress
                      address={selectedProvider.address}
                    />
                  ) : null}
                </Layout.Stack>
              </Layout.Group>
            )}

            {/* Main compare card starts here */}
            {isExpandedCardOpen && isCompareViewOpen ? (
              <CompareExpandedCard
                adobeIndex={index}
                compareCheckboxes={compareCheckboxes}
                headers={headers}
                selectedProvider={selectedProvider}
              />
            ) : null}
          </Layout.Stack>
        </Box>
        <RemoveLink
          index={index}
          removeItem={removeItem}
          selectedProvider={selectedProvider}
        />
      </Flex>
      <ConfirmationModal
        closeButtonText={
          t('COMPARE_DRAWER_CONFIRMATION_MODAL.VIEW_PROVIDER_BUTTON') || ''
        }
        dataForAnalytics={{
          linkName: PRACTITIONER_NAME_FOR_ANALYTICS,
          linkLocation: `modal:compare card:position ${index + 1}`,
          modalName: NEW_TAB_CONFIRMATION,
          linkType: undefined,
          customAttributesBlock,
        }}
        handleClickCb={handleClickCb}
        isViewProvider
        locationForAnalytics={`modal:${NEW_TAB_CONFIRMATION}`}
        modal={modal}
        modalName={modalName}
      />
    </div>
  );
};
