import React from 'react';

import * as LazyComponents from '../../frontends/ProviderSearch/routes/PSXHome/lazyComponents';
import { getCurrentMember } from '../../utils/user.utils';
import { CareCategories, LOB } from '../Constants';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';

export type Props = {
  selectedChipValue: string;
};

export const DisclaimerContent = ({ selectedChipValue }: Props) => {
  const { lineOfBusiness = '' } = getCurrentMember();

  const getDisclaimer = (selectValue) => {
    switch (selectValue) {
      case CareCategories.PRIMARY_CARE:
      case CareCategories.MEDICAL_SPECIALISTS:
      case CareCategories.BEHAVIORAL_HEALTH:
      case CareCategories.DENTAL:
        return <LazyComponents.DisclaimersContainer />;
      case CareCategories.VISION:
        return <LazyComponents.DisclaimersContainer isVisionCare />;
      default:
        return null;
    }
  };

  switch (lineOfBusiness) {
    case LOB.MNR:
      return <LazyComponents.DisclaimersContainer isLandingPage />;
    case LOB.CNS:
      return <DisclaimersContainer />;
    default:
      return getDisclaimer(selectedChipValue);
  }
};
