import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  convertUrlParamsToObject,
  getOnlyDefinedValuesFromObject,
} from '../../common/Utils';
import { getDetailsNormalizedProviderId } from '../../utils/providerDetails.utils';
import { DetailsState, DetailsStore, StoreKeys } from './detailsStore';

const store = (set) => ({
  [StoreKeys.DETAILS_STATE]: {},
  [StoreKeys.SET_DETAILS_STORE]: (values: DetailsState) =>
    set((state) => ({
      [StoreKeys.DETAILS_STATE]: {
        ...state[StoreKeys.DETAILS_STATE],
        ...values,
      },
    })),
  [StoreKeys.CLEAR_DETAILS_STORE]: (defaultValues = {}) =>
    set({ [StoreKeys.DETAILS_STATE]: { ...defaultValues } }),
});

const detailsStore = persist(store, {
  name: 'detailsStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (
    persistedState: DetailsStore,
    currentState: DetailsStore
  ): DetailsStore => {
    const urlParams = new URLSearchParams(window.location.search);
    const {
      providerId,
      locationId,
      showDirection,
      isTieredDataCard,
      specialtySearch,
      avatar,
      inNetwork,
      providerName,
      primarySpeciality,
      professionalDesignation,
      rating,
      npi,
      providerDetails,
      imageLocation,
      searchByProvider,
      isOHBS,
      isTieredSnackCard,
      organizationCode,
      lob,
      coverageType,
    }: any = convertUrlParamsToObject(urlParams.entries());
    const normalizedProviderId = getDetailsNormalizedProviderId(
      coverageType,
      lob,
      providerId
    );

    const values = getOnlyDefinedValuesFromObject({
      providerId: normalizedProviderId,
      locationId,
      showDirection,
      isTieredDataCard,
      specialtySearch,
      avatar,
      inNetwork,
      providerName,
      primarySpeciality,
      professionalDesignation,
      rating,
      npi,
      providerDetails,
      imageLocation,
      searchByProvider,
      isOHBS,
      isTieredSnackCard,
      organizationCode,
      lob,
      coverageType,
    });

    return {
      ...currentState,
      ...persistedState,
      detailsState: {
        ...currentState?.detailsState,
        ...persistedState?.detailsState,
        ...values,
      },
    };
  },
}) as any;

export const useDetailsStore = create(devtools(detailsStore));
