import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const DependentsRow = styled(Flex, {
  variants: {
    view: {
      list: {
        display: 'block',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        '@screen < $sm': {
          width: '100%',
          height: 'auto',
          maxHeight: '700px',
          '&>div': {
            width: '100%',
          },
        },
      },
      grid: {
        overflow: 'hidden',
        width: 'auto !important',
        minHeight: '95px',
        paddingLeft: '3px',
      },
    },
  },
});

export const DependentsCard = styled(Card, {
  '&.abyss-card-root': {
    width: '255.55px',
    backgroundColor: '#F2F2F2',
    wordBreak: 'break-word',
    overflow: 'hidden',
    height: '88px',
    borderRadius: '8px',
    padding: '12px 16px',
  },
});

export const IfpDependentsCard = styled(Card, {
  '&.abyss-card-root': {
    width: '250px',
    backgroundColor: '$primary2',
    wordBreak: 'break-word',
    overflow: 'hidden',
    height: '136px',
    borderRadius: '12px',
    border: '1px solid $gray3',
    padding: '10px 11px',
  },
  variants: {
    view: {
      list: {
        '&.abyss-card-root': { padding: '10px 0px' },
      },
    },
  },
});

export const IfpChoosePCPContentCard = styled(Card, {
  '&.abyss-card-root': {
    width: '226px',
    height: '88px',
    backgroundColor: '#FEF7E5',
    padding: '8px',
    gap: '4px',
    display: 'flex',
    borderRadius: '4px',
  },
});

export const DependentChoosePCPButton = styled(Button, {
  fontSize: '$md',
  fontWeight: '$semibold',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  '&.abyss-button-root': {
    width: '128px',
    height: '42px',
  },
});

export const DependentChoosePCPByText = styled(Text, {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '18px',
  '&> div': {
    display: 'inline',
  },

  '@screen < $sm': {
    maxWidth: '246px',
  },
  '@screen < $md': {
    fontSize: '14.22px !important',
    maxWidth: '100%',
  },
});

export const DependentChoosePCPContainerWrap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  width: '185px',
});

export const IfpDependentName = styled('div', {
  fontWeight: '$bold',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  overflow: 'hidden',
  color: '#323334',
  height: '26px',

  variants: {
    view: {
      list: {
        margin: '0 0 0 16px',
      },
    },
  },
});

export const DependentWithPCPCardContainer = styled('div', {
  '@screen < $sm': {
    marginRight: '4px',
  },
  '.abyss-card-root': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
  },
  '.abyss-card-section': {
    borderRadius: '8px',
    backgroundColor: '$white',
    padding: '16px 16px',
    height: '88px',
    width: '255.55px',
    '@screen < $sm': {
      padding: '16px',
    },
  },
  variants: {
    view: {
      list: {
        '.abyss-card-root': {
          width: 'auto',
          margin: '0 16px 12px 16px',
        },
        '.abyss-layout-group': {
          '@screen < $sm': {
            '.abyss-c-fAMcLx': { width: '24px', height: '24px' },
            justifyContent: 'space-between',
          },
        },
        '.abyss-card-section': {
          width: 'auto',
          minWidth: '350px',
          '@screen < $sm': {
            minWidth: '0px',
          },
        },
      },
      grid: {
        '.abyss-card-section': {
          width: '253.55px',
          height: '86px',
          margin: '0',
          padding: '16px 16px',
        },
      },
    },
  },
});

export const DependentPCPAvatar = styled(Flex, {
  width: '56px',
  height: '56px',
  minWidth: '56px',
  minHeight: '56px',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const DependentName = styled('div', {
  fontWeight: '$bold',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  overflow: 'hidden',
  color: '#323334',

  variants: {
    view: {
      list: {
        width: '250px',
        fontSize: '12.64px',
        fontWeight: '700',
        lineHeight: '16px',
        '@screen < $sm': {
          width: 'auto',
        },
      },
      grid: {
        width: '120px !important',
        '@screen < $sm': {
          width: '124px',
        },
      },
    },
  },
});

export const carouselStyle = {
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    width: 'auto',
    paddingLeft: '3px',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    gap: '12px',
    width: '255.55px',
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '8px',
    padding: '0px',
    height: '20px',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 4px 0 4px',
      display: 'block',
      width: 'auto',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 8px 0 8px',
  },
};

export const slideStyle = {
  'abyss-slide-container': {
    paddingLeft: '0',
    backgroundColor: '$white',
  },
};

export const slideContainerStyle = {
  'abyss-slide-content-wrapper': {
    padding: '0',
    visibility: 'visible',
  },
};

export const DependentWithoutPCPCardContainer = styled('div', {
  '@screen < $sm': {
    marginRight: '4px',
  },
  '.abyss-card-root': {
    border: '0',
    boxShadow: 'none',
  },
  '.abyss-card-section': {
    backgroundColor: '$neutralGray2',
    borderRadius: '8px',
    height: '88px',
    width: '255.55px',

    '@screen < $sm': {
      padding: '16px',
    },
  },
  variants: {
    view: {
      list: {
        '.abyss-card-root': {
          width: 'auto',
          margin: '0 16px 12px 16px',
        },

        '.abyss-card-section': {
          minWidth: '376px',
          margin: '0 16px 0 16px',
          '@screen < $sm': {
            minWidth: '342px',
          },
        },
      },
      grid: {
        '.abyss-card-section': {
          width: '255.55px',
          padding: '16px',
          '@screen < $sm': {
            width: '202px',
          },
        },
      },
    },
  },
});
