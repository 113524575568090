import { styled } from '@abyss/web/tools/styled';

export const getCarouselStyle = ({
  isExpandedCardOpen,
  isCompareViewOpen,
}) => ({
  'abyss-carousel-root': {
    width: '100%',
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: isExpandedCardOpen && isCompareViewOpen ? '277px' : '274px',
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '12px',
    marginBottom: '12px',
    padding: '0px',
    height: '20px',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 4px 0 4px',
      display: 'block',
      width: 'auto',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 8px 0 8px',
  },
});

export const compareEmptyCarouselStyle = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px dashed #929496',
    width: '262px',
    borderRadius: '8px',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    '@screen < $sm': {
      width: '146px',
      padding: '12px 6px 12px 6px',
    },
  },
};

export const placeHolderTextStyle = {
  'abyss-text-root': {
    fontSize: '$md',
    paddingLeft: '12%',
    alignContent: 'center',
    justifyContent: 'center',
    fontWeight: '$medium',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#6E7072',
  },
};

export const CompareDrawerContainer = styled('div', {
  marginTop: '16px',
  marginLeft: '16px',
  overflow: 'auto',
});
