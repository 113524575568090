import { styled } from '@abyss/web/tools/styled';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Text } from '@abyss/web/ui/Text';

import { PSXIcon } from '../PSXIcon';

export const HealthGradesStyled = styled('div', {
  backgroundColor: '$white',
  dynamic: ({ cssProps: { isWidget } }) => ({
    '> div': {
      paddingBottom: isWidget ? '16px' : '10px',
    },
  }),
});

export const StyledIcon = styled(PSXIcon, {
  height: '35px !important',
  marginTop: 16,
});

export const StyledDisclaimerText = styled(Text, {
  color: '$gray7 !important',
  fontSize: '12.64px !important',
  fontWeight: 700,
  lineHeight: '16px',
});

export const disclaimerLinkStyles = {
  'abyss-link-root': {
    fontSize: '12.64px',
    lineHeight: '16px',
  },
};

export const CNSOHDisclaimerLinkStyles = {
  'abyss-flex-root': {
    gap: '14px',
    padding: 0,
    '& a': {
      justifyContent: 'flex-start',
      textAlign: 'left',
      display: 'block',
      fontSize: '$base',
      fontWeight: '$bold',
      width: 'fit-content',
      '&:not(:first-of-type)': {
        margin: '16px 0',
      },
    },
  },
};

export const disclaimerPDFLinkStyles = {
  'abyss-link-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    width: '100%',
    fontSize: '9px',
    fontFamily: '$primary',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '$interactive1',
  },
};

export const StyledMarkdown = styled(Markdown, {
  fontSize: '12.64px',
  lineHeight: '16px',
  color: '$gray8',
  p: {
    color: '$gray8',
  },
});

export const CNSDisclaimersLinkStyles = {
  'abyss-flex-root': {
    gap: '14px',
    padding: 0,
    marginBottom: '16px',
    '& button': {
      justifyContent: 'flex-start',
      textAlign: 'left',
      display: 'block',
      fontSize: '$base',
      fontWeight: '$bold',
      width: 'fit-content',
    },
  },
};
