import { Divider } from '@abyss/web/ui/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import {
  BottomWrapper,
  BottomWrapperChoosePCP,
  ChooseAsPCPButton,
} from './DataCard.style';
import { Direction } from './Direction';
import { Phone } from './Phone';
import { Schedule } from './Schedule';

type Props = {
  choosePCP: boolean;
  providerId: string;
  sectionTypeDerived: string;
  handleChoosePCPClick: any;
  headers: ResponseHeaders;
  getLinkPositionIndex: any;
  linkLocationName: string;
  index?: number;
  name: string;
  primaryPhone: string;
  practitioner?: Provider;
  providerType?: string;
  searchTerm?: string;
  handleAdobeSearchResultClickEvent: any;
  selectedFilters?: string;
  handleNavigationOnClick: any;
  npi;
  docasapPracticeId: string;
  locationId: string;
  doShowDocASAP?: boolean | string;
  isCTADisplayLogic?: boolean;
  showDirection: any;
  isTieredDataCard: boolean;
};
export const ChoosePCP = ({
  selectedFilters,
  choosePCP,
  providerId,
  locationId,
  sectionTypeDerived,
  handleChoosePCPClick,
  headers,
  getLinkPositionIndex,
  linkLocationName,
  index,
  name,
  primaryPhone,
  practitioner,
  providerType,
  searchTerm,
  handleAdobeSearchResultClickEvent,
  handleNavigationOnClick,
  npi,
  docasapPracticeId,
  doShowDocASAP = false,
  isCTADisplayLogic = false,
  showDirection,
  isTieredDataCard,
}: Props) => {
  const { t } = useTranslation();

  const scheduleButton = React.useMemo(
    () => (
      <Schedule
        handleSearchResultClick={handleAdobeSearchResultClickEvent}
        id={providerId}
        isCTADisplayLogic={isCTADisplayLogic}
        locationId={locationId}
        npi={npi}
        phone={primaryPhone}
        practiceId={docasapPracticeId}
        providerName={name}
        sectionType={sectionTypeDerived}
        showDirection={showDirection}
        isTieredDataCard={isTieredDataCard}
      />
    ),
    []
  );

  const callButton = React.useMemo(
    () => (
      <Phone
        choosePCP={choosePCP}
        doShowDocASAP={doShowDocASAP}
        headers={headers}
        id={providerId}
        indexForAnalytics={getLinkPositionIndex(index)}
        isCTADisplayLogic={isCTADisplayLogic}
        locationForAnalytics={linkLocationName}
        phone={primaryPhone}
        practitioner={practitioner}
        providerType={providerType}
        searchTerm={searchTerm}
        sectionType={sectionTypeDerived}
        selectedFilters={selectedFilters}
      />
    ),
    []
  );

  return (
    <React.Fragment>
      {choosePCP ? (
        <BottomWrapperChoosePCP alignItems="center" justify="space-between">
          <ChooseAsPCPButton
            data-auto-testid="choose-pcp-button"
            data-providerid={providerId}
            data-testid={`${'choose-pcp-button'}-${sectionTypeDerived.replace(
              / /g,
              '-'
            )}`}
            onClick={handleChoosePCPClick}
            size="$sm"
            variant="outline"
          >
            {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
          </ChooseAsPCPButton>
          {callButton}
        </BottomWrapperChoosePCP>
      ) : (
        <BottomWrapper justify="space-evenly">
          {!isCTADisplayLogic ? (
            <>
              <Direction
                handleClick={() => {
                  handleAdobeSearchResultClickEvent('address');
                  handleNavigationOnClick();
                }}
                sectionType={sectionTypeDerived}
              />
              <div style={{ height: 'auto' }}>
                <Divider margin={0} orientation="vertical" width={1} />
              </div>{' '}
            </>
          ) : null}
          {callButton}
          {isCTADisplayLogic
            ? doShowDocASAP
              ? scheduleButton
              : null
            : scheduleButton}
        </BottomWrapper>
      )}
    </React.Fragment>
  );
};
