export const languageNotSupportedModalStyles = {
  'abyss-modal-content-container': {
    width: '784px',
    height: '330px',
    padding: '8px, 32px, 32px, 32px',
    '@screen < $md': {
      width: '343px',
      height: '398px',
    },
  },
  '&.abyss-modal-content-title': {
    padding: '16px 0px 0px 0px',
  },
};

export const languageNotSupportedModalInfoStyles = {
  '&.abyss-modal-section-root': {
    color: '#333333',
    padding: '0px 16px 8px 16px',
    fontSize: '18px',
    fontWeight: '400',
    textAlign: 'center',
    '@screen < $md': {
      fontSize: '16px',
    },
  },
};

export const languageNotSupportedModalTitleStyles = {
  '&.abyss-heading-root': {
    fontWeight: '600',
    fontSize: '28.83px',
    lineHeight: '36px',
    textAlign: 'center',
  },
};
