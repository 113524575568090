import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { useTranslation } from 'react-i18next';

import { PortalUrl } from '../../../common/Portal';
import { PortalContext } from '../../../context/PortalContext';
import { getPortalConfigUrls } from '../../../utils/portalConfig.utils';
import { benefitsCarouselScreen } from '../../ConstantsStyles';
import { slideContainerStyle } from '../../MyBenefits/MyBenefits.style';
import { makeAllSlidesAriaHidden } from '../../Utils/a11yUtils/a11yUtils';
import { carouselStyle } from '../WhereToGetHelp.Style';
import { WhereToGetHelpContainer } from '../WhereToGetHelpContainer/WhereToGetHelpContainer';

interface WhereToGetHelpDataProps {
  id: string;
  key: string;
  helpTitle: string;
  helpDescription: string;
  helpCTA: string;
  helpLinkText: string;
  linkUrl: string;
  cardColor: string;
  icon: string;
  accessibilityText: string;
  dntHelpKey: any;
  displayOrder: string;
}
interface Props {
  whereToGetHelpContent: WhereToGetHelpDataProps[];
}

interface BoxContentProps {
  whereToGetHelpData: WhereToGetHelpDataProps;
  index: number;
}

export const WhereToGetHelpCard = ({ whereToGetHelpContent }: Props) => {
  const displayCarousel = useMediaQuery(benefitsCarouselScreen);
  const BoxContent = ({ whereToGetHelpData, index }: BoxContentProps) => {
    const { t } = useTranslation();
    const {
      helpTitle,
      helpDescription,
      icon,
      linkUrl,
      accessibilityText,
      helpLinkText,
      displayOrder,
      dntHelpKey,
    } = whereToGetHelpData;

    const cardKeyValues = {
      cardsBackgrounds: {
        0: '$whereToGetHelpCardBg1',
        1: '$whereToGetHelpCardBg2',
        2: '$whereToGetHelpCardBg3',
      },
    };

    return (
      <WhereToGetHelpContainer
        accessibilityText={`${t(accessibilityText)}`}
        buttonLabel={`${t(helpLinkText)}`}
        dntHelpKey={dntHelpKey}
        cardBGColor={cardKeyValues.cardsBackgrounds[index % 3]}
        cardContent={helpDescription}
        cardIcon={icon}
        cardTitle={helpTitle}
        displayOrder={displayOrder}
        url={linkUrl}
      />
    );
  };

  const portalUrls: PortalUrl[] = getPortalConfigUrls();

  // Filter whereToGetHelpContent based on linkUrl and portalUrl, then sort based on displayOrder if it's available
  const filterWhereToGetHelpContent = whereToGetHelpContent
    .filter(
      (helpContent) =>
        helpContent.linkUrl ||
        portalUrls.some(
          (portalUrl) => portalUrl.name === helpContent.dntHelpKey
        )
    )
    .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0));

  const slides = filterWhereToGetHelpContent
    ?.map((whereToGetHelpData, i) => {
      return (
        <Slide>
          <Slide.Container css={slideContainerStyle}>
            <BoxContent index={i} whereToGetHelpData={whereToGetHelpData} />
          </Slide.Container>
        </Slide>
      );
    })
    .filter(Boolean); // filter out null values

  // If there are no slides to show, don't render the Carousel
  if (slides.length === 0) {
    return null;
  }

  return (
    <Carousel
      css={
        (displayCarousel || whereToGetHelpContent.length > 2) &&
        whereToGetHelpContent.length !== 1
          ? carouselStyle
          : {
              ...carouselStyle,
              'abyss-carousel-minimal-controls': {
                display: 'none',
              },
            }
      }
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={slides}
      slidesPerView={1}
      variant="none"
    />
  );
};
