import React from 'react';

import { Constants } from '../../../../common/Constants';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import {
  isCnsMember,
  isMnrMember,
} from '../../../../common/Utils/memberUtils/memberUtils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';

type Props = {
  sectionType: string;
  isVisionCare: boolean;
};

export const ViewAllDisclaimers = ({ sectionType, isVisionCare }: Props) => {
  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);
  const isCNS = isCnsMember(lineOfBusiness);

  if (isMNR) {
    return (
      sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
        <HealthGradesContainerStyled>
          <DisclaimersContainer isViewAllDisclaimer />
        </HealthGradesContainerStyled>
      )
    );
  } else if (isCNS) {
    return <DisclaimersContainer />;
  } else {
    return (
      <React.Fragment>
        {sectionType !== Constants.RESULT_SECTION.FACILITY &&
          sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
            <HealthGradesContainerStyled>
              <DisclaimersContainer isVisionCare={isVisionCare} />
            </HealthGradesContainerStyled>
          )}
        {isVisionCare &&
        (sectionType === Constants.RESULT_SECTION.FACILITY ||
          sectionType === Constants.RESULT_SECTION.PROVIDER_GROUPS) ? (
          <HealthGradesContainerStyled>
            <DisclaimersContainer isVisionCare={isVisionCare} />
          </HealthGradesContainerStyled>
        ) : null}
      </React.Fragment>
    );
  }
};
