import {
  ChipState,
  PERSIST_KEYS,
  StoreKeys,
} from '../../store/useChipStore/chipStore';
import { ConstantsRoutes } from '../ConstantsRoutes';

export { showMemberSelector } from './memberSelectorUtils';
export { getFeatureFlag } from './getFeatureFlag';
export { getDistanceFilterOption } from './filters.utils';

export const isDetailsPage = (page) =>
  page === ConstantsRoutes.FACILITY_DETAILS.key ||
  page === ConstantsRoutes.PROVIDER_DETAILS.key ||
  page === ConstantsRoutes.PROVIDER_GROUP_DETAILS.key;

export const interleave = (originalArray, thingToPutBetweenArray) =>
  []
    .concat(...originalArray.map((n) => [n, thingToPutBetweenArray]))
    .slice(0, -1);

export const hasValue = (value) => value !== undefined && value !== null;

export const getOnlyDefinedValuesFromObject = (obj) => {
  const definedValues = {};
  Object.keys(obj).forEach((key) => {
    if (hasValue(obj[key])) {
      definedValues[key] = obj[key];
    }
  });
  return definedValues;
};

export const convertObjectToUrlParams = (chipStore: ChipState, paramsObj) => {
  const persistValues = chipStore
    ? Object.fromEntries(
        Object.entries(chipStore).filter(([k]) =>
          PERSIST_KEYS.includes(<StoreKeys>k)
        )
      )
    : {};

  // Adding includeSpecialityRollupCodes increases the size of the URL parameters and the http header, so it is being removed.
  // includeSpecialityRollupCodes values are avalilable in typeaheadStoreValues.
  const restParamsObj = { ...paramsObj };
  if ('includeSpecialityRollupCodes' in restParamsObj) {
    delete restParamsObj.includeSpecialityRollupCodes;
  }

  if ('combinedIncludeSpecialityRollupCodes' in restParamsObj) {
    delete restParamsObj.combinedIncludeSpecialityRollupCodes;
  }

  if ('pesKeyword' in restParamsObj) {
    delete restParamsObj.pesKeyword;
  }

  if ('typeaheadLinkName' in restParamsObj) {
    delete restParamsObj.typeaheadLinkName;
  }

  if ('vertical' in restParamsObj) {
    delete restParamsObj.vertical;
  }

  if ('specialtyCode' in restParamsObj) {
    delete restParamsObj.specialtyCode;
  }

  if ('includeOrgTypeCodes' in restParamsObj) {
    delete restParamsObj.includeOrgTypeCodes;
  }

  if ('excludeOrgTypeCodes' in restParamsObj) {
    delete restParamsObj.excludeOrgTypeCodes;
  }

  const mergedVals = {
    ...persistValues,
    ...restParamsObj,
  };

  const urlParams: string[] = [];
  Object.keys(mergedVals).forEach((key) => {
    if (hasValue(mergedVals[key])) {
      urlParams.push(`${key}=${encodeURIComponent(mergedVals[key])}`);
    }
  });
  return `?${urlParams.join('&')}`;
};

export const convertUrlParamsToObject = (urlParams) => {
  const paramsObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlParams) {
    if (hasValue(value)) {
      paramsObj[key] =
        value === 'true' || value === 'false' ? value === 'true' : value;
    }
  }
  return paramsObj;
};
