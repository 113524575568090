import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { IconBrand } from '@abyss/web/ui/IconBrand';

export const BenefitsCardBox = styled(Box, {
  dynamic: ({ cssProps: { displayCarousel } }) => ({
    marginBottom: displayCarousel ? '' : '16px',
  }),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '8px',
  position: 'relative',
  flex: '1 0 0',
  padding: '0',
  alignSelf: 'stretch',
});

export const LinkContainer = styled('div', {
  display: 'flex',
  marginTop: '0px',
  height: '42px',
  zIndex: '2',
});

export const StyledBenefitsIcon = styled(IconBrand, {
  zIndex: '1',
  position: 'absolute',
  bottom: '0px',
  height: '100% !important',
  right: '5px',
  '@screen < $sm': {
    bottom: '0px',
    height: '100% !important',
    right: '-5px',
  },
});

export const slideContainerStyle = {
  'abyss-slide-content-wrapper': {
    padding: '0',
    visibility: 'visible',
    '> div': {
      height: '100%',
    },
  },
};

export const carouselStyle = {
  'abyss-carousel-root': {
    display: 'flex',
    flexWrap: 'wrap',
    '@screen >= $sm': {
      width: '100%',
      marginTop: '16px',
    },
    '@screen < $sm': {
      width: '100%',
      marginTop: '12px',
    },
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    marginBottom: '12px',
    width: '100%',
    willChange: 'transform',
    zIndex: 0,
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '384px',
    gap: '24px',
    height: 'auto',
    '@screen < $sm': {
      width: '311px',
      gap: '24px',
    },
  },
  'abyss-carousel-minimal-controls': {
    marginTop: '0px',
    padding: '0px',
    height: '20px',
    marginBottom: '20px',
    width: '100%',
    '@screen < $sm': {
      marginBottom: 0,
    },
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 4px 0 4px',
      display: 'block',
      width: 'auto',
    },
  },
  'abyss-carousel-minimal-nav-icon-right': {
    color:
      'var(--abyss-colors-carousel-color-background-bullets-isActive) !important',
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    color:
      'var(--abyss-colors-carousel-color-background-bullets-isActive) !important',
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 8px 0 8px',
  },
};

export const GridColStyle = {
  padding: '12px 12px 0 12px',
  '@screen < $sm': {
    padding: '0px',
  },
};
