import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Divider } from '@abyss/web/ui/Divider';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../hooks/useLagoon';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../models/ProviderDetails';
import { portalShouldShowOnlineScheduling } from '../../../utils/portalConfig.utils';
import { h2SmallerFontForResponsive, mobileOnly } from '../../ConstantsStyles';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import {
  ByPhoneScheduling,
  OnlineScheduling,
} from '../ProviderLocationsDetails/ProviderScheduling/index';
import { InnerScheduleContainer } from './ProviderLocation.style';

type Props = {
  providerDetails: ProviderDetails;
  selectedLocation: ProviderLocation;
  locationId: string;
  isTieredDataCard?: boolean;
};

export const ProviderScheduling = ({
  providerDetails,
  selectedLocation,
  locationId,
  isTieredDataCard,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const featureFlags = useLagoon('feature-flags')();
  const availabilityEnabled = getFeatureFlag(
    featureFlags,
    'ENABLE_AVAILABILITY_SECTION'
  );
  const mobileScreen = useMediaQuery(mobileOnly);
  const {
    docasapPracticeId: practiceID,
    isDocasapScheduled,
    docAsapMorningAvailabilityDate,
    docAsapAfterNoonAvailabilityDate,
    docAsapEveningAvailabilityDate,
  } = selectedLocation;
  const { npi } = providerDetails;
  const providerAvailabilities = [
    docAsapMorningAvailabilityDate,
    docAsapAfterNoonAvailabilityDate,
    docAsapEveningAvailabilityDate,
  ];
  const doShowDocASAP =
    availabilityEnabled && isDocasapScheduled && practiceID && !isWidget;
  const { phones } = selectedLocation || {};
  const showOnlineScheduling = portalShouldShowOnlineScheduling();
  const providerId = providerDetails.providerId;
  return doShowDocASAP ? (
    <InnerScheduleContainer>
      <div style={{ width: '100%' }}>
        <Heading
          css={{ ...h2SmallerFontForResponsive, paddingBottom: '12px' }}
          data-auto-testid="scheduling-main-title"
          data-testid="scheduling-main-title"
          display="h4"
          offset={1}
        >
          {t('SCHEDULE_MODAL.SCHEDULING')}
        </Heading>

        {!mobileScreen ? (
          <Layout.Group alignItems="left" space={32}>
            {showOnlineScheduling ? (
              <OnlineScheduling
                availabilityDates={providerAvailabilities}
                npi={npi}
                practiceId={practiceID}
                providerId={providerId}
                locationId={locationId}
                isTieredDataCard={isTieredDataCard}
              />
            ) : null}
            <ByPhoneScheduling phoneNumbers={phones} />
          </Layout.Group>
        ) : (
          <Layout.Stack alignItems="left" space={16} width="100%">
            {showOnlineScheduling ? (
              <OnlineScheduling
                availabilityDates={providerAvailabilities}
                npi={npi}
                practiceId={practiceID}
                providerId={providerId}
                locationId={locationId}
                isTieredDataCard={isTieredDataCard}
              />
            ) : null}
            <Divider
              color="#E5E5E6"
              height={1}
              margin={0}
              minWidth={343}
              orientation="horizontal"
            />
            <ByPhoneScheduling phoneNumbers={phones} />
          </Layout.Stack>
        )}
      </div>
    </InnerScheduleContainer>
  ) : null;
};
