import { ALL, ReverseCoverageTypesCodes } from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { useCoverageType } from '../hooks/useCoverageType';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { StoreKeys } from '../hooks/useStore/state';
import { useStore } from '../hooks/useStore/useStore';
import {
  getCurrentMember,
  getPlanVariationCode,
  getReportingCode,
} from './user.utils';

export const returnSuppressFlag = (featureDnt: string) => {
  const { suppressionLagoonData, memberPolicy: storeMemberPolicy } = useStore(
    StoreKeys.UI_FEATURE_SUPPRESSION
  );

  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();
  const memberReportingCode = getReportingCode(currentMember, coverageType);
  const memberPlanVariationCode = getPlanVariationCode(
    currentMember,
    coverageType
  );
  const memberPVRC = memberPlanVariationCode?.concat(memberReportingCode);
  const costEstimatesFlag =
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES;
  const [featureSuppressionFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.FEATURE_SUPPRESSION,
  ]);

  const suppressFeature = suppressionLagoonData?.find((item) => {
    const policyIdsArray = item.policyIds.split(',');
    const isPolicyIncluded =
      policyIdsArray.includes(ALL) ||
      policyIdsArray.includes(storeMemberPolicy);
    let ispvrcCodeIncluded = true;
    if (item.DNT_feature === costEstimatesFlag && item.pvrcCodes) {
      const pvrcCodesArray = item.pvrcCodes.split(',');
      ispvrcCodeIncluded = pvrcCodesArray.includes(memberPVRC);
    }
    return (
      item.DNT_feature === featureDnt && isPolicyIncluded && ispvrcCodeIncluded
    );
  })?.active;

  return !!(featureSuppressionFlag && suppressFeature);
};

export const suppressCostEstimatesForPolicies = (
  coverageType,
  costEstimateDnt,
  costEstimateEmpireDnt
) => {
  const suppressCostEstimate = returnSuppressFlag(costEstimateDnt);
  const suppressCostEstimateEmpireDnt = returnSuppressFlag(
    costEstimateEmpireDnt
  );
  const suppressCostEstimateEmpire =
    coverageType !== ReverseCoverageTypesCodes.MEDICAL &&
    suppressCostEstimateEmpireDnt;

  return suppressCostEstimate || suppressCostEstimateEmpire;
};

export const returnInclusionFlag = (featureDnt) => {
  const { includeLagoonData, memberPolicy: storeMemberPolicy } =
    useStore(StoreKeys.UI_FEATURE_INCLUSION) || {};
  const [featureInclusionFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.FEATURE_INCLUSION,
  ]);
  const includeFeature = includeLagoonData?.find((item) => {
    const isPolicyIncluded = item.policyIds.includes(storeMemberPolicy);
    return item.dntFeature === featureDnt && isPolicyIncluded;
  })?.active;

  return !!(featureInclusionFlag && includeFeature);
};
