import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../hooks/useLagoon';
import { useSpecialtiesResult } from '../../hooks/useSpecialties';
import { BhProviderIcons } from '../../models/Lagoon';
import { Provider } from '../../models/Provider';
import {
  Address as ProviderAddress,
  ProviderType,
} from '../../models/ProviderDetails';
import { ProviderSearchFilters } from '../../models/ProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { isProviderStartDateInFuture } from '../../utils/date.utils';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getBhIcon } from '../../utils/iconography.utils';
import { clearRoute, setResultIcon } from '../../utils/map.utils';
import { getProviderDetailsRouteUrl } from '../../utils/providerDetails.utils';
import { getShowCost } from '../../utils/showCost.utils';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { ImageComponent } from '../ImageComponent';
import { InNetworkBadgeWithDate } from '../InNetworkBadge/InNetworkBadge';
import { TerminationStatusIndicator } from '../TerminationBadge/TerminationStatusIndicator';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import {
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { useCheckTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { Address } from './Address';
import { ChoosePCP } from './ChoosePCP';
import { CostIndicatorPopover } from './CostIndicatorPopover';
import {
  AvatarDataCard,
  CardContainer,
  DataCardAvatarContainer,
  FlexStyles,
  InNetworkBadgeContainer,
  MilesAndAddressWrapper,
  NetworkWrapper,
  PrimarySectionStyle,
  ResponsiveText,
  SecondarySectionStyle,
  TerminationDateIndicatorContainer,
  VirtualVisitLabel,
  VirtualVisitPatientStyle,
  VirtualVisitStyle,
  costIndicatorStyles,
  displayVirtualVisitStyle,
  milesTextStyles,
  specialityNameToolTipStyles,
  virtualVistsToolTipStyles,
} from './DataCard.style';
import {
  getLinkNameForSearchResultEvent,
  getLinkPositionIndex,
  handlePatientReviewsView,
  inOrOutOfNetwork,
} from './DataCardUtils';
import { Miles } from './Miles';
import { NameHeading } from './NameHeading';
import { ProviderRating } from './ProviderRating';

type Props = {
  practitioner?: Provider;
  surveyCount?: number;
  updatePin?: (practitioner?: Provider) => void;
  handleDirectionClick?: () => void;
  index?: number;
  enableCost?: boolean;
  pageNumber?: number;
  map?: any;
  selectedFilters?: string;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  usedAt: string;
  searchTerm?: string;
  setNavigateToDirections?: (a: boolean) => void;
  headers: ResponseHeaders;
  providerType?: string;
  linkLocationName: string;
  sectionTypeDerived: string;
  providerId: string;
  locationId: string;
  isTieredProvider: boolean;
  speciality?: string;
  setSearchFilters: (a: ProviderSearchFilters) => void;
  searchFilters: ProviderSearchFilters;
  setSelectedId: (a: string | null) => void;
  setHighlightId: (a: { providerId: string; from: string }) => void;
  highlightId: { providerId: string; from: string };
  showBestMatchDetails: boolean;
  setIsOpen: (a: boolean) => void;
  virtualCareIndicatorEnabled: boolean;
  cardRef: React.RefObject<HTMLElement>;
  highlightClassName: string;
  cardContainerCss: any;
  handleHoverOnCard: (a: any) => void;
  imgRef: any;
  name: string;
  providerSpeciality: string;
  suppressCostEstimates: boolean;
  suppressPatientReviews: boolean;
  healthGradeRating?: string;
  networkStatus?: string;
  npi: string;
  distance?: string;
  address?: ProviderAddress;
  primaryPhone: string;
  choosePCP: boolean;
  docasapPracticeId: string;
  organizationCode?: string[];
  networkEndDate?: string;
  isNetworkAlertIndicator?: boolean;
  isMNR?: boolean;
  networkStartDate?: string;
  isInNetworkBadge?: boolean;
};
const mileAndAddressSize = (
  isNetworkAlertIndicator,
  displayFutureTerminationDate,
  enablePatientView,
  displayVirtualVisit
) =>
  isNetworkAlertIndicator
    ? !displayFutureTerminationDate
      ? '20px'
      : '0px'
    : enablePatientView && displayVirtualVisit
      ? '0'
      : '20px';

const cardContainerHeightCss = (
  isNetworkAlertIndicator,
  isInNetworkBadge,
  displayInNetworkBadgeEnabled,
  isCurrentMemberPreEffective
) =>
  isNetworkAlertIndicator ||
  (displayInNetworkBadgeEnabled &&
    isCurrentMemberPreEffective &&
    isInNetworkBadge)
    ? {
        height: '250px',
        '@screen < $sm': { width: '320px', height: '230px' },
      }
    : '';

export const DataCard = ({
  searchTerm,
  selectedFilters,
  index,
  providerType,
  linkLocationName,
  headers,
  practitioner,
  sectionTypeDerived,
  providerId,
  locationId,
  isTieredProvider,
  speciality,
  setSearchFilters,
  searchFilters,
  pageNumber,
  handleDirectionClick,
  usedAt,
  setNavigateToDirections,
  setSelectedId,
  map,
  setHighlightId,
  updatePin,
  setRouteEndCoords,
  highlightId,
  showBestMatchDetails,
  setIsOpen,
  virtualCareIndicatorEnabled,
  cardRef,
  highlightClassName,
  cardContainerCss,
  handleHoverOnCard,
  imgRef,
  name,
  providerSpeciality,
  suppressCostEstimates,
  suppressPatientReviews,
  healthGradeRating,
  networkStatus,
  npi,
  enableCost,
  distance,
  address,
  primaryPhone,
  choosePCP,
  docasapPracticeId,
  organizationCode,
  networkEndDate,
  isNetworkAlertIndicator,
  networkStartDate,
  isInNetworkBadge,
}: Props) => {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { portalData } = useContext(PortalContext);
  const portalShowCost = getShowCost(portalData);

  const [showDirection, setShowDirection] = useState(false);

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const {
    chipValue: value,
    coverageType: coverageTypeValue,
    setChipValue: setStoreChipValue,
    setCoverageType: setStoreCoverageType,
  } = chipStore;

  const featureFlags = useLagoon('feature-flags')();
  const schedulingEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ONLINE_SCHEDULING
  );
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );
  const ctaDisplayLogic = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PSX_CTA_DISPLAY_LOGIC
  );
  const doShowDocASAP = schedulingEnabled && docasapPracticeId;
  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();

  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const { choosePCP: choosePCPFlag, setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );

  const handleAdobeSearchResultClickEvent = (clickSection?: string) => {
    adobeSearchResultClickEvent({
      filters: selectedFilters,
      linkName: getLinkNameForSearchResultEvent(providerType, clickSection),
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(
          appendCoverageTypes(practitioner, coverageType)
        ),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
      term: searchTerm,
    });
  };

  const coverageType = coverageTypeValue;
  const bhProviderIcons: BhProviderIcons[] = useLagoon('bh_provider_icons')();
  const { data: bhSpecialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });
  const bhIcon =
    coverageType === 'B'
      ? getBhIcon([], [providerSpeciality], bhProviderIcons, bhSpecialtyCodes)
      : '';
  const handleDetailsOnClick = ({ showDirection }) => {
    handleAdobeSearchResultClickEvent();
    const typeaheadStoreValues = {
      sectionType: sectionTypeDerived,
      searchTerm,
    };
    const detailsStoreValues = {
      providerId,
      locationId,
      showDirection,
      isTieredDataCard: isTieredProvider,
      specialtySearch: speciality,
      organizationCode,
    };
    setStoreChipValue(value);
    setStoreCoverageType(coverageType);

    setDetailsStore(detailsStoreValues);
    setTypeaheadSearchStore(typeaheadStoreValues);
    setSearchFilters({
      ...searchFilters,
      PageNumber: pageNumber,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.IS_PROVIDER_CARD_SELECTED,
      true
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_SPECIALTY,
      speciality
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID,
      locationId
    );

    const providerDetailsRoute = getProviderDetailsRouteUrl(
      chipStore,
      providerId,
      locationId,
      showDirection,
      isTieredProvider,
      sectionTypeDerived,
      choosePCP
    );
    navigate(providerDetailsRoute);
  };

  const showDataDirection = () => {
    if (handleDirectionClick) {
      if (usedAt === Constants.MOBILE_LIST_VIEW) {
        setNavigateToDirections?.(true);
        setSelectedId(locationId || null);
      } else {
        handleDirectionClick();
      }
    } else {
      handleDetailsOnClick({ showDirection: true });
      setShowDirection(true);
    }
  };
  const handleOnHeadingBlur = () => {
    if (map?.current) {
      setResultIcon(
        map.current,
        +providerId,
        false,
        setHighlightId,
        providerId
      );
      updatePin?.(undefined);
    }
    setHighlightId({ providerId: '', from: usedAt });
  };

  const handleNavigationOnClick = () => {
    if (map?.current && setRouteEndCoords) {
      clearRoute(
        map.current,
        setRouteEndCoords,
        highlightId.providerId,
        setHighlightId
      );
      setSelectedId(null);
    }
    showDataDirection();
  };

  const onCardClick = () => {
    if (showBestMatchDetails) {
      handleAdobeSearchResultClickEvent('datacard click');
      setIsOpen(true);
    } else {
      handleDetailsOnClick({ showDirection: false });
      setShowDirection(false);
    }
  };
  const handleChoosePCPClick = (event) => {
    adobeSearchResultClickEvent({
      linkName: 'choose-pcp-button',
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(
          appendCoverageTypes(practitioner, coverageType)
        ),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
    });

    setAnalyticsState({
      originLinkNameForAnalytics: 'choose-pcp-button',
    });

    const pcpStoreValues = {
      choosePCPId: event.currentTarget.dataset.providerid,
      selectedProviderType: providerType,
      choosePCP: choosePCPFlag,
    };
    setPCPSearchState(pcpStoreValues);

    const urlParams = convertObjectToUrlParams(chipStore, {
      ...pcpStoreValues,
      value,
      coverageType,
    });
    navigate(`${ConstantsRoutes.CHOOSE_PCP_LOCATION.path}${urlParams}`);
  };

  const suppressVirtualCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );

  const displayVirtualVisit =
    virtualCareIndicatorEnabled &&
    !suppressVirtualCare &&
    practitioner?.address &&
    !!practitioner?.virtualCareOffered?.length &&
    (providerType === ProviderType.PRACTITIONER || ProviderType.MEDICAL_GROUP);

  const imgSrc = practitioner?.imageLocation;

  const enablePatientView = handlePatientReviewsView(
    enableCost,
    practitioner,
    displayVirtualVisit
  );
  const textSize = enablePatientView ? 5 : 4;

  const displayFutureTerminationDate = useCheckTerminationDate(networkEndDate);
  const displayInNetworkBadge = isProviderStartDateInFuture(networkStartDate);

  return (
    <CardContainer
      className={highlightClassName}
      css={{
        ...cardContainerCss,
        ...cardContainerHeightCss(
          isNetworkAlertIndicator,
          isInNetworkBadge,
          displayInNetworkBadgeEnabled,
          isCurrentMemberPreEffective
        ),
      }}
      data-auto-testid="generic-data-card"
      data-testid="generic-data-card"
      id={`data-card-for-map-${providerId}`}
      onMouseEnter={handleHoverOnCard}
      ref={cardRef}
    >
      <Card.Section
        css={PrimarySectionStyle}
        data-testid="data-card-details-primary-section"
        onClick={handleDetailsOnClick}
        style={{ cursor: 'pointer' }}
      >
        <Flex>
          <Flex>
            <DataCardAvatarContainer>
              <AvatarDataCard>
                {ImageComponent({
                  imgRef,
                  providerType,
                  imgSrc,
                  bhIcon,
                  width: '$dataCardForMapIconSize',
                  height: '$dataCardForMapIconSize',
                })}
              </AvatarDataCard>
              {displayVirtualVisit && (
                <Flex color="$primary1" css={displayVirtualVisitStyle}>
                  <IconMaterial
                    aria-hidden="true"
                    color="white"
                    icon="videocam"
                    size={16}
                    variant="filled"
                  />
                </Flex>
              )}
            </DataCardAvatarContainer>
          </Flex>

          <Flex css={{ flex: 1, marginLeft: '8px' }} direction="column">
            {displayFutureTerminationDate && (
              <TerminationDateIndicatorContainer data-testid="termination-date-container">
                <TerminationStatusIndicator
                  message={t(
                    'DISPLAY_FUTURE_TERMINATION_DATE.NETWORK_ALERT_DATACARD'
                  )}
                />
              </TerminationDateIndicatorContainer>
            )}
            {displayInNetworkBadge && displayInNetworkBadgeEnabled && (
              <InNetworkBadgeContainer data-testid="in-network-badge-container">
                <InNetworkBadgeWithDate
                  message={t('DISPLAY_IN_NETWORK_BADGE.IN_NETWORK_BADGE_PCP')}
                  startDate={networkStartDate}
                />
              </InNetworkBadgeContainer>
            )}
            <NameHeading
              handleHoverOnCard={handleHoverOnCard}
              handleOnHeadingBlur={handleOnHeadingBlur}
              name={name}
              providerId={providerId}
              sectionTypeDerived={sectionTypeDerived}
            />
            <Flex css={{ padding: '2px 0 2px $sm' }} justify="space-between">
              <Flex>
                {providerSpeciality && (
                  <Tooltip
                    content={providerSpeciality}
                    css={specialityNameToolTipStyles}
                    position="top"
                    positionOffset={8}
                  >
                    <ResponsiveText
                      as="div"
                      data-auto-testid={`data-card-specialty-${sectionTypeDerived}`}
                      data-testid={`data-card-specialty-${sectionTypeDerived}`}
                      fontWeight="$medium"
                    >
                      {providerSpeciality}
                    </ResponsiveText>
                  </Tooltip>
                )}
              </Flex>
              <Flex>
                <NetworkWrapper
                  data-testid={`data-card-network-${sectionTypeDerived}-section`}
                >
                  <IconMaterial
                    color={networkStatus ? '$success1' : ''}
                    data-auto-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                    data-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                    icon="check_circle"
                    size={16}
                  />
                  <ResponsiveText
                    data-auto-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                    data-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                    fontWeight="$medium"
                  >
                    {t(inOrOutOfNetwork(networkStatus))}
                  </ResponsiveText>
                </NetworkWrapper>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card.Section>
      <Card.Section
        css={SecondarySectionStyle}
        data-testid="data-card-details-secondary-section"
      >
        <Flex justify="space-between">
          <Flex direction="column">
            {enablePatientView && (
              <Flex
                alignItems="center"
                css={{
                  'abyss-flex-root': {
                    cursor: healthGradeRating !== null ? 'pointer' : '',
                  },
                }}
                data-testid="data-card-clickable-reviews"
                onClick={healthGradeRating !== null && handleDetailsOnClick}
              >
                {sectionTypeDerived === Constants.RESULT_SECTION.PROVIDER &&
                  !suppressPatientReviews && (
                    <ProviderRating
                      data-testid={`data-card-rating-${sectionTypeDerived}`}
                      healthGradeRating={healthGradeRating}
                      providerId={providerId}
                      sectionTypeDerived={sectionTypeDerived}
                      size={mobileScreen ? 12 : 13}
                      surveyCount={practitioner?.surveyCount}
                      underline={false}
                    />
                  )}
              </Flex>
            )}
            {displayVirtualVisit && !suppressVirtualCare && (
              <Layout.Group
                css={
                  enablePatientView
                    ? VirtualVisitPatientStyle
                    : VirtualVisitStyle
                }
              >
                <IconMaterial
                  color="$virtualVisitIconColor"
                  icon="videocam"
                  size={20}
                />
                <Tooltip
                  content={t('DETAILS_PAGE.OFFERS_VIRTUAL_CARE')}
                  css={virtualVistsToolTipStyles}
                  position="top"
                  positionOffset={8}
                >
                  <VirtualVisitLabel
                    as="div"
                    data-auto-testid={`data-card-virtual-visits-${sectionTypeDerived}`}
                    data-testid={`data-card-virtual-visits-${sectionTypeDerived}`}
                    fontWeight="$medium"
                  >
                    {t('DETAILS_PAGE.OFFERS_VIRTUAL_CARE')}
                  </VirtualVisitLabel>
                </Tooltip>
              </Layout.Group>
            )}
          </Flex>
          <Flex alignItems="end" css={FlexStyles} direction="column">
            {!enablePatientView && (
              <Flex
                css={{
                  'abyss-flex-root': {
                    cursor: healthGradeRating !== null ? 'pointer' : '',
                  },
                }}
                data-testid="data-card-clickable-reviews"
                onClick={healthGradeRating !== null && handleDetailsOnClick}
              >
                {sectionTypeDerived === Constants.RESULT_SECTION.PROVIDER &&
                  !suppressPatientReviews && (
                    <ProviderRating
                      data-testid="data-card-rating-Provider"
                      healthGradeRating={healthGradeRating}
                      providerId={providerId}
                      sectionTypeDerived={sectionTypeDerived}
                      size={textSize}
                      surveyCount={practitioner?.surveyCount}
                      underline={false}
                    />
                  )}
              </Flex>
            )}
            {enableCost &&
              portalShowCost &&
              practitioner?.costIndicator &&
              !suppressCostEstimates && (
                <Flex css={costIndicatorStyles}>
                  <CostIndicatorPopover
                    callFrom="datacard"
                    costIndicator={practitioner.costIndicator}
                    headers={headers}
                    indexForAnalytics={getLinkPositionIndex(index)}
                    locationForAnalytics={linkLocationName}
                    practitioner={practitioner}
                    providerType={providerType}
                    searchTerm={searchTerm}
                    selectedFilters={selectedFilters}
                  />
                </Flex>
              )}
          </Flex>
        </Flex>
      </Card.Section>

      {!ctaDisplayLogic ? (
        <MilesAndAddressWrapper
          css={{
            paddingTop: mileAndAddressSize(
              isNetworkAlertIndicator,
              displayFutureTerminationDate,
              enablePatientView,
              displayVirtualVisit
            ),
          }}
          data-auto-testid="datacard-address"
          data-testid="datacard-address"
        >
          <IconMaterial
            data-auto-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
            data-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
            icon="location_on"
            onClick={onCardClick}
            size={24}
          />
          <Flex css={milesTextStyles}>
            <Miles
              distance={distance || ''}
              headers={headers}
              id={providerId}
              indexForAnalytics={getLinkPositionIndex(index)}
              isCTADisplayLogic={ctaDisplayLogic}
              locationForAnalytics={linkLocationName}
              onClosePopup={() => setIsOpen(false)}
              practitioner={practitioner}
              providerType={providerType}
              searchTerm={searchTerm}
              sectionType={sectionTypeDerived}
              selectedFilters={selectedFilters}
            />
          </Flex>

          <div
            data-auto-testid={`data-card-location-address-${address}-${providerId}`}
            data-testid={`data-card-location-address-${address}-${providerId}`}
            onClick={onCardClick}
            onKeyDown={onCardClick}
            role="button"
            tabIndex={-1}
          >
            <Address
              address={address}
              id={providerId}
              isCTADisplayLogic={ctaDisplayLogic}
              sectionType={sectionTypeDerived}
            />
          </div>
        </MilesAndAddressWrapper>
      ) : (
        <MilesAndAddressWrapper
          css={{
            paddingTop: mileAndAddressSize(
              isNetworkAlertIndicator,
              displayFutureTerminationDate,
              enablePatientView,
              displayVirtualVisit
            ),
            cursor: 'pointer',
          }}
        >
          <Flex
            css={milesTextStyles}
            data-auto-testid="datacard-address"
            data-testid="datacard-address"
            onClick={() => {
              handleAdobeSearchResultClickEvent('address');
              handleNavigationOnClick();
            }}
          >
            <IconMaterial
              data-auto-testid={`data-card-directions-icon-${sectionTypeDerived}-${providerId}`}
              data-testid={`data-card-directions-icon-${sectionTypeDerived}-${providerId}`}
              icon="directions"
              size={24}
              style={{
                marginTop: 0,
                marginRight: 4,
              }}
            />

            <Address
              address={address}
              id={providerId}
              isCTADisplayLogic={ctaDisplayLogic}
              sectionType={sectionTypeDerived}
            />
          </Flex>
          <div
            data-auto-testid={`data-card-location-address-${address}-${providerId}`}
            data-testid={`data-card-location-address-${address}-${providerId}`}
            role="button"
            tabIndex={-1}
          >
            <Miles
              distance={distance || ''}
              headers={headers}
              id={providerId}
              indexForAnalytics={getLinkPositionIndex(index)}
              isCTADisplayLogic={ctaDisplayLogic}
              locationForAnalytics={linkLocationName}
              onClosePopup={() => setIsOpen(false)}
              onMilesClick={onCardClick}
              practitioner={practitioner}
              providerType={providerType}
              searchTerm={searchTerm}
              sectionType={sectionTypeDerived}
              selectedFilters={selectedFilters}
            />
          </div>
        </MilesAndAddressWrapper>
      )}

      <ChoosePCP
        choosePCP={choosePCP}
        doShowDocASAP={doShowDocASAP}
        docasapPracticeId={docasapPracticeId}
        getLinkPositionIndex={getLinkPositionIndex}
        handleAdobeSearchResultClickEvent={handleAdobeSearchResultClickEvent}
        handleChoosePCPClick={handleChoosePCPClick}
        handleNavigationOnClick={handleNavigationOnClick}
        headers={headers}
        index={index}
        isCTADisplayLogic={ctaDisplayLogic}
        isTieredDataCard={isTieredProvider}
        linkLocationName={linkLocationName}
        locationId={locationId}
        name={name}
        npi={npi}
        practitioner={practitioner}
        primaryPhone={primaryPhone}
        providerId={providerId}
        providerType={providerType}
        searchTerm={searchTerm}
        sectionTypeDerived={sectionTypeDerived}
        selectedFilters={selectedFilters}
        showDirection={showDirection}
      />
    </CardContainer>
  );
};
