import { Constants } from '../../../../common/Constants';

export const modifyCategory = (
  category,
  search,
  isPseudoRollupSearch,
  isConditionSearch,
  typeaheadAoeCodes,
  isBHAoeSearch
) => {
  if (
    (category.chipCategory === Constants.VISION &&
      search === Constants.ONLINE_PROVIDERS) ||
    isPseudoRollupSearch ||
    isConditionSearch ||
    isBHAoeSearch
  ) {
    return {
      ...category,
      name: '',
      includeSpecialityRollupCodes: [''],
      ...(isBHAoeSearch && {
        aoeCodes: typeaheadAoeCodes,
        prioritizeAoe: true,
      }),
    };
  } else {
    return {
      ...category,
      name: search,
      includeSpecialityRollupCodes: category.includeSpecialityRollupCodes,
    };
  }
};
