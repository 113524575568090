import { storage } from '@abyss/web/tools/storage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { Constants } from '../../common/Constants';
import { CareCategories, LOB } from '../../common/Constants';
import { FeatureFlags } from '../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import {
  getIndicatorsForImpressions,
  getTier1Indicator,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../context/CountySearchContext';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getSuppressFacilityFlags } from '../../utils/providerDetails.utils';
import { getLoggedInMember } from '../../utils/user.utils';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { StoreKeys } from '../useStore/state';
import { useStore } from '../useStore/useStore';
import { useAdobePageTrackEvent } from './useAdobePageTrackEvent';

type Props = {
  numberOfApiCalls?: number;
  skipCostIndicator?: boolean;
};

export const useAdobeProvidersImpressionBlock = ({
  numberOfApiCalls,
  skipCostIndicator,
}: Props) => {
  const [allProviders, setProviders] = useState([] as any[]);
  const [numberOfCalls, setNumberOfCalls] = useState(0);
  const { longitude, latitude } = useGeoLocationStorage();
  const [isTracked, setIsTracked] = useState(false);
  const { contextValue: value } = useChipStore(
    useShallow((state: ChipState) => ({
      contextValue: state.chipValue,
    }))
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const loggedInMember = getLoggedInMember();
  const featureFlags = useLagoon('feature-flags')();
  const loggedInMemberIndex = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
  );
  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${value} overview`,
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(
              allProviders,
              featureFlags,
              skipCostIndicator,
              {
                suppressPremiumCare,
                ddpCodeUser,
                suppressFacilityFlags,
                memberHasRPK,
                isCountySearch,
              }
            )
          : '',
      providerTier: getTier1Indicator(showTierProviderTag, allProviders),
    },
  });

  const onProvidersLoad = useCallback(
    (providers: any) => {
      if (Array.isArray(providers?.snackCardList)) {
        setProviders((prev) => [...prev, ...providers.snackCardList]);
      }
      setNumberOfCalls((prev) => prev + 1);
    },
    [setProviders, setNumberOfCalls]
  );
  const onError = useCallback(
    (error: any) => {
      if (error) {
        setNumberOfCalls((prev) => prev + 1);
      }
    },
    [setNumberOfCalls]
  );

  const reSetValue = () => {
    setNumberOfCalls(0);
    setProviders([]);
    setIsTracked(false);
  };

  useEffect(() => {
    if (latitude && longitude) {
      setNumberOfCalls(0);
      setProviders([]);
      setIsTracked(false);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    const isVisionCNS =
      value === CareCategories.VISION &&
      loggedInMember?.lineOfBusiness === LOB.CNS;

    if (isVisionCNS) {
      setIsTracked(true);
      adobePageTrackEvent();
    }

    const apisCount = numberOfApiCalls || 0;
    if (apisCount == 0 || apisCount != numberOfCalls || isTracked) {
      return;
    }

    if (loggedInMemberIndex != null) {
      setIsTracked(true);
      adobePageTrackEvent();
    }
  }, [numberOfApiCalls, numberOfCalls, loggedInMemberIndex]);
  return [onProvidersLoad, onError, reSetValue];
};
