export const getWhereToGetHelpContent = (whereToGetHelps, activeChip) =>
  whereToGetHelps?.filter(
    (whereToGetHelp) =>
      whereToGetHelp.activeTabs
        ?.split(',')
        ?.find(
          (activeTab) =>
            activeTab?.trim().toLowerCase() === activeChip?.toLowerCase()
        ) && whereToGetHelp?.isActive
  );

export const isEmptyPlanPurchaseProvisions = (planPurchaseProvisions) =>
  !planPurchaseProvisions ||
  planPurchaseProvisions.length === 0 ||
  planPurchaseProvisions === '.';

export const getFilteredWhoCanHelpContent = (
  whereToGetHelpContent,
  memberState: string,
  memberGroupId: string
) => {
  const liveMarketAndPolicyEligibleCards = whereToGetHelpContent.filter(
    (x) =>
      (x?.liveMarkets?.includes(memberState) || !x.liveMarkets) &&
      (x?.policyNumber?.includes(memberGroupId) || !x.policyNumber)
  );

  return liveMarketAndPolicyEligibleCards;
};
export const getFilteredWhereToGetHelpContent = (
  apiResponse,
  whereToGetHelpContent
) => {
  const {
    planPurchaseProvisions = [],
    isEapEligible,
    eapPhoneNumber,
  } = apiResponse;
  whereToGetHelpContent = whereToGetHelpContent || [];

  const defaultCards = whereToGetHelpContent.filter(
    (x) =>
      isEmptyPlanPurchaseProvisions(x.planPurchaseProvisions) &&
      !x.isEapEligable
  );
  const planProvisionSpecificCards = whereToGetHelpContent.filter(
    (x) =>
      !isEmptyPlanPurchaseProvisions(x.planPurchaseProvisions) &&
      x.planPurchaseProvisions
        .split(',')
        .some((x) => planPurchaseProvisions.includes(x))
  );

  if (planProvisionSpecificCards.length > 0) {
    return [...defaultCards, ...planProvisionSpecificCards];
  }

  const eapEligibleCards = whereToGetHelpContent.filter(
    (x) => x.isEapEligable && isEapEligible
  );

  if (eapEligibleCards.length === 0) {
    return defaultCards;
  }

  const updatedContentCards = eapEligibleCards.map((card) =>
    replaceRowWithEapPhoneNumber(card, eapPhoneNumber)
  );

  return [...defaultCards, ...updatedContentCards];
};

const replaceRowWithEapPhoneNumber = (row, eapPhoneNumber) => {
  const formattedPhoneNumber = eapPhoneNumber.includes('-')
    ? eapPhoneNumber
    : eapPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '1-$1-$2-$3');

  row.helpLinkText = row.helpLinkText.replace(
    '##########',
    formattedPhoneNumber
  );
  row.linkUrl = row.linkUrl.replace('##########', formattedPhoneNumber);
  return row;
};
