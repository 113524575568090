import { Badge } from '@abyss/web/ui/Badge';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { formatProviderStartDate } from '../../utils/date.utils';
import { InNetworkBadgeWrapper } from './InNetworkBadge.styled';

type InNetworkWithDateProps = {
  startDate: string;
  message: string;
};

type InNetworkProps = {
  message: string;
};

export const InNetworkBadge = ({ message = '' }: InNetworkProps) => {
  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();
  return isCurrentMemberPreEffective ? (
    <InNetworkBadgeWrapper data-testid="in-network-badge">
      <Badge
        css={{ margin: '0 0 $sm 0' }}
        data-testid="in-network-badge"
        variant="info"
      >
        {message}
      </Badge>
    </InNetworkBadgeWrapper>
  ) : null;
};

export const InNetworkBadgeWithDate = ({
  message = '',
  startDate = '',
}: InNetworkWithDateProps) => {
  const isCurrentMemberPreEffective = useCurrentMemberIsPreEffective();
  return isCurrentMemberPreEffective ? (
    <InNetworkBadgeWrapper data-testid="in-network-badge-with-date">
      <Badge data-testid="in-network-badge-with-date" variant="info">
        {message} {formatProviderStartDate(startDate)}
      </Badge>
    </InNetworkBadgeWrapper>
  ) : null;
};
