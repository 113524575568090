import { storage } from '@abyss/web/tools/storage';

import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  NEW_TAB_CONFIRMATION,
  ProviderTypes,
} from '../../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../../common/ConstantsRoutes';
import { convertObjectToUrlParams } from '../../../../../../common/Utils';
import { CustomAttributesBlock } from '../../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';

export const openTabOrNavigateOnClick = (
  providerId,
  navigate,
  options,
  chipStoreValues,
  setDetailsStore,
  customAttributesBlock?: CustomAttributesBlock
) => {
  const detailsRouteMap = {
    [ProviderTypes.MEDICALGROUP.toLowerCase()]:
      ConstantsRoutes.PROVIDER_GROUP_DETAILS.path,
    [ProviderTypes.ORGANIZATION.toLowerCase()]:
      ConstantsRoutes.FACILITY_DETAILS.path,
    [ProviderTypes.PRACTITIONER.toLowerCase()]:
      ConstantsRoutes.PROVIDER_DETAILS.path,
  };

  const detailsRoute =
    detailsRouteMap[options?.providerType?.toLowerCase()] || '';

  const detailsStoreValues = {
    providerId,
  };
  setDetailsStore(detailsStoreValues);

  storage.session.set(
    Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
    providerId
  );
  adobeLinkTrackEvent({
    name: 'view provider',
    location: `modal:${NEW_TAB_CONFIRMATION}`,
    type: 'internal',
    destinationUrl: `${detailsRoute}`,
    customAttributesBlock,
  });
  // @ts-ignore
  const urlParams = convertObjectToUrlParams(customAttributesBlock, {
    chipValue: chipStoreValues.chipValue,
    coverageType: chipStoreValues.coverageType,
    ...detailsStoreValues,
  });
  if (options && options?.openInNewTab) {
    window.open(`/findcare${detailsRoute}${urlParams}`, '_blank');
  } else {
    navigate(`${detailsRoute}${urlParams}`);
  }
};
