import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PortalContext } from '../../../../context/PortalContext';
import {
  LastModifiedFilter,
  Option,
  ProviderSearchFilters,
} from '../../../../models/ProviderSearch';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  EAP_OPTION_LABEL,
  FilterCollectionModel,
} from '../../../Constants';

interface Props {
  defaultSearchFilters: ProviderSearchFilters;
  lastModifiedFilter: LastModifiedFilter;
  selectedFilters: ProviderSearchFilters;
  onFilterSelectionChange: (
    data: ProviderSearchFilters,
    lastModifiedModel: string
  ) => void;
  setSelectedFiltersContainerHeight: (a: number) => void;
}

export const SelectedFilters = ({
  defaultSearchFilters,
  lastModifiedFilter,
  selectedFilters,
  onFilterSelectionChange,
  setSelectedFiltersContainerHeight,
}: Props) => {
  const selectedOptions: {
    [key: string]: {
      label: string;
      type: string;
      value: string | number | boolean;
      dnt_label: string;
    };
  } = {};
  const { t } = useTranslation();
  const selectedFiltersRef = useRef<HTMLDivElement>(null);

  Object.keys(selectedFilters).forEach((model) => {
    let newEntryKey = '';
    if (Array.isArray(selectedFilters[model])) {
      selectedFilters[model].forEach((option: Option) => {
        if (!option.hide) {
          newEntryKey = `${model}_${option.label}_${option.value}`;
          selectedOptions[newEntryKey] = {
            label: option?.label,
            type: model,
            value: option?.value,
            dnt_label: option?.dnt_label,
          };
        }
      });
    } else {
      const option: Option = selectedFilters[model];
      if (option.value && !option.hide) {
        newEntryKey = `${model}_${option.label}_${option.value}`;
        selectedOptions[newEntryKey] = {
          label: option?.label,
          type: model,
          value: option?.value,
          dnt_label: option?.dnt_label,
        };
      }
    }
  });

  const { portalData } = useContext(PortalContext);
  const portalLockEAPfilter = portalData?.portalConfig?.lockEapFilter;

  const lockedPill = (option) =>
    portalLockEAPfilter && option?.dnt_label === EAP_OPTION_LABEL;

  const resetAllFiltersDataTestId: string = 'reset-all-filter';

  const handleResetFilter = () => {
    adobeLinkTrackEvent({
      name: resetAllFiltersDataTestId,
      location: `modal:${Constants.PROVIDER_RESULTS.ALL_FILTERS_DRAWER}:selected filters`,
    });
    onFilterSelectionChange(defaultSearchFilters, '');
  };

  const handleRemoveFilter = (
    modelObject: {
      label: string;
      type: string;
      value: string | number | boolean;
      dnt_label: string;
    },
    label: string
  ) => {
    if (!lockedPill(modelObject)) {
      const model = modelObject?.type;
      const value = modelObject?.value;
      adobeLinkTrackEvent({
        name: `${label}:remove`,
        location: `modal:${Constants.PROVIDER_RESULTS.ALL_FILTERS_DRAWER}:selected filters`,
      });

      if (Array.isArray(selectedFilters[model])) {
        let updatedSelection: Option[] = selectedFilters[model].filter(
          (option: Option) => option.value !== value
        );
        if (
          updatedSelection.length === 0 &&
          Object.keys(defaultSearchFilters).includes(model)
        ) {
          updatedSelection = defaultSearchFilters[model];
        }
        const currentSelectedFilters = {
          ...selectedFilters,
          [model]: updatedSelection,
        };
        const lastModifiedModel: string =
          model === lastModifiedFilter.model &&
          currentSelectedFilters[model].length > 0
            ? model
            : '';
        onFilterSelectionChange(currentSelectedFilters, lastModifiedModel);
      } else {
        const updatedSelection = selectedFilters[model];
        updatedSelection.value = false;
        const currentSelectedFilters = {
          ...selectedFilters,
          [model]: updatedSelection,
        };
        const lastModifiedModel: string =
          model === lastModifiedFilter.model &&
          currentSelectedFilters[model].length > 0
            ? model
            : '';
        onFilterSelectionChange(currentSelectedFilters, lastModifiedModel);
      }
    }
  };

  const getSelectedFilterLabel = (filter) => {
    switch (filter.type) {
      case FilterCollectionModel.ACCEPTING_NEW_PATIENTS:
        return t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS');
      case FilterCollectionModel.ESSENTIAL_COMMUNITY_PROVIDER:
        return t('CONTENT_FILTERS.ESSENTIAL_COMMUNITY_PROVIDER');
      default:
        return filter.label;
    }
  };

  useEffect(() => {
    if (selectedFilters) {
      setSelectedFiltersContainerHeight(
        selectedFiltersRef.current?.clientHeight
      );
    }
  }, [selectedFiltersRef, selectedOptions]);

  return (
    <div
      ref={selectedFiltersRef}
      style={{
        padding: '0px',
        margin: '0px',
        width: '100%',
        backgroundColor: 'white',
        zIndex: '999',
        position: 'absolute',
        left: 0,
        top: 48,
        borderBottom: '1px solid var(--abyss-colors-gray4)',
        paddingBottom: '24px',
      }}
    >
      {Object.keys(selectedOptions).length > 0 ? (
        <Flex
          aria-label="Filters"
          css={{
            padding: '$lg $md 0 15px',
            gap: '$sm',
          }}
          role="group"
        >
          <Flex
            css={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Text data-testid="filters-heading" fontWeight="$bold">
              {' '}
              {t('CONTENT_FILTERS.FILTERS')}:
            </Text>
            <Text
              color="$interactive1"
              css={{ textDecoration: 'underline', cursor: 'pointer' }}
              data-auto-testid={resetAllFiltersDataTestId}
              data-testid={resetAllFiltersDataTestId}
              fontWeight="$bold"
              onClick={handleResetFilter}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleResetFilter();
                }
              }}
              role="button"
              tabIndex={0}
            >
              {t('CONTENT_FILTERS.RESET_ALL')}
            </Text>
          </Flex>
          {Object.entries(selectedOptions).map(([item, info]) => (
            <Text
              aria-label={`${item}`}
              css={{
                'abyss-text-root': {
                  height: '36px',
                  padding: '6px $sm 6px 14px',
                  border: '1px solid var(--abyss-colors-gray8)',
                  color: 'var(--abyss-colors-gray8)',
                  borderRadius: '90px',
                  display: 'flex',
                  alignItems: 'center',
                  ...(lockedPill(info) && {
                    backgroundColor: 'var(--abyss-colors-gray2)',
                    '&,.abyss-label-root': {
                      cursor: 'not-allowed',
                    },
                  }),
                },
                'abyss-button-content-container': {
                  gap: '$sm',
                },
              }}
              data-auto-testid="selected-filter-chip"
              data-testid="selected-filter-chip"
              key={item}
              onClick={() => handleRemoveFilter(info, item)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleRemoveFilter(info, item);
                }
              }}
              role="button"
              tabIndex={0}
              variant="custom"
            >
              <Label fontWeight="$medium">{getSelectedFilterLabel(info)}</Label>
              {!lockedPill(info) && (
                <IconMaterial
                  aria-label="close-icon"
                  color="$descriptionBlack"
                  css={{
                    cursor: 'pointer',
                  }}
                  data-auto-testid={`${item}-clear-filter`}
                  data-testid={`${item}-clear-filter`}
                  icon="close"
                  onClick={() => handleRemoveFilter(info, item)}
                />
              )}
            </Text>
          ))}
        </Flex>
      ) : null}
    </div>
  );
};
