import { useEffect, useState } from 'react';

import { getGeoLocationFromStorage } from '../../common/PSXHeader/SearchBar/utils';

export const useGeoLocationStorage = () => {
  const [geoLocation, setGeoLocation] = useState(getGeoLocationFromStorage());
  useEffect(() => {
    const getGeoLocation = () => {
      const newGeoLocation = getGeoLocationFromStorage();
      setGeoLocation(newGeoLocation);
    };
    window.addEventListener('updateGeoLocationToStorage', getGeoLocation);
    return () => {
      window.removeEventListener('updateGeoLocationToStorage', getGeoLocation);
    };
  }, []);

  return geoLocation;
};
