import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { find } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import {
  isCnsMember,
  isMnrCosmosMember,
} from '../../../../common/Utils/memberUtils/memberUtils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../../models/FacilityDetails';
import {
  AcoProvider,
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { returnInclusionFlag } from '../../../../utils/featureSuppress';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import {
  getInNetworkStartDate,
  getOfferVirtualCareText,
  getPcmhValue,
  getPracticeLimitations,
  getTexasValue,
  getYesNoNAText,
  isDisplayTexasStatesHealthProvider,
  isHospitals,
  showECP,
  showPcmhAttribute,
} from '../../../../utils/providerDetails.utils';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import {
  getCurrentMember,
  getMemberCoverageTypeSitusState,
} from '../../../../utils/user.utils';
import {
  Constants,
  FacilityCategory,
  ReverseCoverageTypesCodes,
} from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { LinkWithTracking } from '../../../LinkWithTracking/LinkWithTracking';
import { convertObjectToUrlParams } from '../../../Utils';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import { CopyToClipBoard } from '../CopyToClipBoard';
import { InfoProviderContent } from '../InfoProviderContent';
import { IconAlignmentContainer } from '../ProviderLocation.style';
import {
  FlexContainer,
  infoContainer,
  infoProviderContentContainer,
  infoTitleContainer,
} from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';
import { PreferredFacilityInfo } from './PreferredFacilityInfo';

type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

function screenType(screenType: string, isWidget: any) {
  return useMediaQuery(screenType) || isWidget;
}

function showACO(acoProvider: AcoProvider[], isMnr: boolean, isCns: boolean) {
  return acoProvider && !isMnr && !isCns;
}

const bhProgramInfo = (
  isBehavioralHealth,
  isOrganization,
  showBHprograms,
  infoSub,
  infoTit
) => {
  const { t } = useTranslation();
  return isBehavioralHealth && isOrganization && showBHprograms && infoSub ? (
    <InfoProviderContent
      icon="playlist_add_check"
      infoSubtext={infoSub}
      infoTitle={t(infoTit)}
    />
  ) : null;
};

export const AllOtherInfosContainer = ({
  providerDetails,
  selectedLocation,
}: Props) => {
  const featureFlags = useLagoon('feature-flags')();
  const uiMessaging = useLagoon('ui-messaging')();
  const showBHprograms = getFeatureFlag(featureFlags, 'BH_PROGRAMS_DETAILS');
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);

  const mobileScreen = screenType(mobileOnly, isWidget);
  const phoneScreen = screenType(phoneOnly, isWidget);

  const currentMember = getCurrentMember();
  const { lineOfBusiness, population, policyNumber } = currentMember;
  const situsState = getMemberCoverageTypeSitusState(currentMember);

  const isMnr = isMnrCosmosMember(lineOfBusiness, population);
  const isCns = isCnsMember(lineOfBusiness);
  const isCnsOhio = returnInclusionFlag(
    ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_CNS_OH
  );
  const suppressVirtualCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );
  const showNetworkAffiliation = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_NETWORK_AFFILIATION
  );

  const networkAffiliationPopoverContent = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.NETWORK_AFFILIATION_POPOVER_CONTENT,
  })?.message;

  const patientCenterPopoverContent = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING
      .PATIENT_CENTER_MEDICAL_HOMES_POPOVER_CONTENT,
  });

  const texasHealthStepsProvider = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.TEXAS_HEALTH_STEPS_PROVIDER,
  });

  const essentialCommunityProviderContent = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.ESSENTIAL_COMMUNITY_PROVIDER_CONTENT,
  });

  const { navigate } = useRouter();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { coverageType } = chipStore;
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const allConfigs = useLagoon('config')();
  const hospitalsOrgCodes = allConfigs?.find(
    (row) => row.key === 'HOSPITAL_ORG_CODES'
  )?.value;

  const facilityAttributes = useLagoon('facility-attributes')();
  const additionalAttributes = useLagoon('additional-attributes')();

  const { providerId = '', providerType, organizationCode } = providerDetails;

  const category = facilityAttributes?.find(
    (row) => row?.facilitySubCategoryCode === organizationCode?.[0]
  );

  const {
    accessibility,
    facilityProviderSpecialties,
    hasEveningAppointments,
    hasPCPServiceAvailability,
    isExpressAccessProvider,
    languagesSpokenByOffice,
    languagesSpokenByInterpreters,
    networkAffiliations,
    medicalEquipments,
    network,
    pcpId,
    practiceLimitations,
    providerGroupsAffiliations,
    virtualCareOffered,
    acoProvider,
    isTexasHealthStepProvider,
    isPCMH,
    isECP,
  } = selectedLocation;

  const {
    hasAnesthesiology,
    hasEmergencyMedicine,
    hasPathology,
    hasRadiology,
  } = facilityProviderSpecialties || {};

  const isPractitioner = providerType === ProviderType.PRACTITIONER;
  const isOrganization = providerType === ProviderType.ORGANIZATION;
  const isMedicalGroup = providerType === ProviderType.MEDICAL_GROUP;
  const isProviderGroup = providerType === ProviderType.PROVIDER_GROUP;
  const isBehavioralHealth =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
  const isHospital = isHospitals(organizationCode, hospitalsOrgCodes);
  const showACOLabel = showACO(selectedLocation?.acoProvider, isMnr, isCns);
  const inNetworkStartDate = getInNetworkStartDate(network?.start);
  const isTexasValidPolicyId: boolean = isDisplayTexasStatesHealthProvider(
    additionalAttributes,
    coverageType,
    policyNumber,
    providerType,
    isCns
  );

  const practiceLimitation = getPracticeLimitations(
    practiceLimitations?.age,
    practiceLimitations?.gender
  );

  const handleRedirectToProviderGroupDetails = (providerGroupClicked) => {
    const typeaheadStoreValues = {
      sectionType: Constants.RESULT_SECTION.PROVIDER_GROUPS,
    };
    const detailsStoreValues = {
      providerId: providerGroupClicked.id,
    };
    setTypeaheadSearchStore(typeaheadStoreValues);
    setDetailsStore(detailsStoreValues);

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerGroupClicked.id
    );
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...detailsStoreValues,
      ...typeaheadStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${urlParams}`);
  };

  const facilityProgramRes = (programTitle) => {
    const titles = selectedLocation?.facilityProgramIds
      ?.filter(
        (facilityProgram: any) => facilityProgram?.category === programTitle
      )
      .map((facilityProgram: any) => facilityProgram?.title);

    if (!titles || titles.length === 0) {
      return null;
    }

    return (
      <div>
        {titles && titles.length > 0
          ? titles.map((title, index) => <div key={index}>{title}</div>)
          : ''}
      </div>
    );
  };

  const platinumProgramsRes = () => {
    const allTitles =
      selectedLocation?.platinumPrograms?.flatMap(
        (platProgram: any) => platProgram?.titles
      ) || [];

    const formattedTitles = allTitles;

    if (!allTitles || allTitles.length === 0) {
      return null;
    }

    return (
      <div>
        {formattedTitles.map((title, index) => (
          <div key={index}>{title}</div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Flex
        css={infoProviderContentContainer(mobileScreen)}
        data-testid="all-other-infos-container"
      >
        <EmailAndWebsiteInfo selectedLocation={selectedLocation} />

        {!isCns && isOrganization && (
          <PreferredFacilityInfo
            detailType={providerType}
            selectedLocation={selectedLocation}
          />
        )}
        {bhProgramInfo(
          isBehavioralHealth,
          isOrganization,
          showBHprograms,
          platinumProgramsRes(),
          'Platinum programs'
        )}
        {bhProgramInfo(
          isBehavioralHealth,
          isOrganization,
          showBHprograms,
          facilityProgramRes('Mental Health Program'),
          'Mental health programs'
        )}
        {bhProgramInfo(
          isBehavioralHealth,
          isOrganization,
          showBHprograms,
          facilityProgramRes('Substance Abuse Program'),
          'Substance use programs'
        )}
        {bhProgramInfo(
          isBehavioralHealth,
          isOrganization,
          showBHprograms,
          facilityProgramRes('Eating Disorder'),
          'Eating disorder programs'
        )}
        {!suppressVirtualCare && (
          <InfoProviderContent
            icon="videocam"
            infoSubtext={getOfferVirtualCareText(
              virtualCareOffered?.join(', ')
            )}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_VIRTUAL_CARE')}
          />
        )}
        <InfoProviderContent
          icon="date_range"
          infoSubtext={getYesNoNAText(t, hasEveningAppointments)}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_EVENING_APPOINTMENT')}
        />

        <InfoProviderContent
          icon="translate"
          infoSubtext={languagesSpokenByOffice?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_OFFICE')}
        />
        {showACOLabel ? (
          <InfoProviderContent
            icon="corporate_fare"
            infoSubtext={
              capitalizeEachWord(acoProvider[0]?.acoNetworkDescription) ||
              t('PROVIDER_LOCATIONS_DETAILS.NOT_APPLICABLE')
            }
            infoTitle={t(
              'PROVIDER_LOCATIONS_DETAILS.ACCOUNTABLE_CARE_ORGANIZATION'
            )}
          />
        ) : null}
        <InfoProviderContent
          icon="translate"
          infoSubtext={languagesSpokenByInterpreters
            ?.sort((a, b) => a.localeCompare(b))
            .join(', ')}
          infoTitle={t(
            'PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_INTERPRETER'
          )}
        />

        <InfoProviderContent
          icon="accessibility"
          infoSubtext={accessibility?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
        />
        {!isBehavioralHealth ? (
          <InfoProviderContent
            icon="local_hospital"
            infoSubtext={getYesNoNAText(t, hasPCPServiceAvailability)}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PCP_SERVICES_AVAILABLE')}
            variant="outlined"
          />
        ) : null}

        {isCnsOhio && (
          <React.Fragment>
            <InfoProviderContent
              icon="model_training"
              infoSubtext={getYesNoNAText(t, undefined)}
              infoTitle={t(
                'PROVIDER_LOCATIONS_DETAILS.SPECIALIZED_STAFF_TRAINING'
              )}
            />
            <InfoProviderContent
              icon="connect_without_contact"
              infoSubtext={getYesNoNAText(t, undefined)}
              infoTitle={t(
                'PROVIDER_LOCATIONS_DETAILS.STAFF_CULTURAL_COMPETENCE'
              )}
            />
          </React.Fragment>
        )}

        {isMnr && showNetworkAffiliation ? (
          <InfoProviderContent
            icon="corporate_fare"
            infoSubtext={networkAffiliations?.join(', ')}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.NETWORK_AFFILIATION')}
            popoverContent={networkAffiliationPopoverContent}
            popoverTitle={
              t('PROVIDER_LOCATIONS_DETAILS.NETWORK_AFFILIATION') || undefined
            }
            showPopover
          />
        ) : null}

        {isPractitioner ? (
          <FlexContainer
            css={{
              marginBottom: '3px',
              '@screen < $sm': { marginBottom: '0' },
            }}
          >
            <IconAlignmentContainer>
              <IconMaterial
                color="$primary1"
                css={{ marginTop: '3px' }}
                icon="group"
                size={18}
              />
              <Text css={infoContainer(phoneScreen)} fontWeight="$semibold">
                {t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_GROUP')}
                {providerGroupsAffiliations?.length > 0 ? (
                  providerGroupsAffiliations.map((group) => (
                    <Text
                      css={infoContainer(phoneScreen)}
                      fontWeight="$normal"
                      key={`provider-group-${group.value}`}
                    >
                      {isWidget ? (
                        <React.Fragment>
                          {capitalizeEachWord(group.value)}
                        </React.Fragment>
                      ) : (
                        <LinkWithTracking
                          analyticsInfo={{
                            name: group?.value,
                            location: `body:${Constants.PROVIDER_DETAILS.LOCATIONS_TAB.GROUP_AFFILIATION}`,
                          }}
                          css={infoContainer(phoneScreen)}
                          data-auto-testid="redirect-to-provider-group-details"
                          data-testid="redirect-to-provider-group-details"
                          fontWeight="$bold"
                          isDisabled={group?.id === null}
                          isStandardAnchor
                          onClick={() =>
                            handleRedirectToProviderGroupDetails(group)
                          }
                        >
                          {capitalizeEachWord(group.value)}
                        </LinkWithTracking>
                      )}
                    </Text>
                  ))
                ) : (
                  <Text
                    css={infoContainer(phoneScreen)}
                    data-testid="provider-group-not-available"
                    fontWeight="$normal"
                  >
                    {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                  </Text>
                )}
              </Text>
            </IconAlignmentContainer>
          </FlexContainer>
        ) : null}

        {isTexasValidPolicyId ? (
          <InfoProviderContent
            icon="group"
            infoSubtext={getTexasValue(isTexasHealthStepProvider)}
            infoTitle={t(
              'PROVIDER_LOCATIONS_DETAILS.TEXAS_HEALTH_STEPS_PROVIDER'
            )}
            popoverContent={texasHealthStepsProvider?.message}
            popoverTitle={texasHealthStepsProvider?.title}
            showModal
          />
        ) : null}
        <InfoProviderContent
          icon="pan_tool"
          infoSubtext={
            practiceLimitation?.trim()?.length > 0
              ? practiceLimitation
              : t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
          }
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
        />
        {isOrganization &&
        category?.DNT_category?.toLowerCase() ===
          FacilityCategory.DURABLE_MEDICAL_EQUIPMENT.toLowerCase() ? (
          <React.Fragment>
            <InfoProviderContent
              icon="accessible"
              infoSubtext={medicalEquipments?.join(', ')}
              infoTitle={t(
                'PROVIDER_LOCATIONS_DETAILS.MEDICAL_EQUIPMENT_OFFER'
              )}
            />
          </React.Fragment>
        ) : null}
        {showPcmhAttribute(
          lineOfBusiness,
          isMedicalGroup,
          isProviderGroup,
          isPractitioner
        ) ? (
          <InfoProviderContent
            icon="home"
            infoSubtext={getPcmhValue(isPCMH)}
            infoTitle={t(
              'PROVIDER_LOCATIONS_DETAILS.PATIENT_CENTER_MEDICAL_HOME'
            )}
            popoverContent={patientCenterPopoverContent?.message}
            popoverTitle={patientCenterPopoverContent?.title}
            showModal
          />
        ) : null}
        {showECP(lineOfBusiness, situsState) && (
          <InfoProviderContent
            icon="group"
            infoSubtext={getYesNoNAText(t, isECP)}
            infoTitle={t(
              'PROVIDER_LOCATIONS_DETAILS.ESSENTIAL_COMMUNITY_PROVIDER'
            )}
            popoverContent={essentialCommunityProviderContent?.message}
            popoverTitle={essentialCommunityProviderContent?.title}
            showModal
          />
        )}
        <InfoProviderContent
          icon="event"
          infoSubtext={inNetworkStartDate}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
          variant="outlined"
        />
        {isPractitioner && !isBehavioralHealth ? (
          <InfoProviderContent
            icon="pin_drop"
            infoSubtext={pcpId}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_ID')}
            variant="outlined"
          >
            <Flex
              css={{
                gap: '$xs',
                '@screen < $sm': { width: 'auto' },
              }}
            >
              <Text
                color="$info1"
                css={infoContainer(phoneScreen)}
                fontWeight="$bold"
              >
                {pcpId?.replace(' ', '') ?? ''}
              </Text>

              <CopyToClipBoard
                gap="0"
                label={t(
                  'PROVIDER_LOCATIONS_DETAILS.COPY_PROVIDER_ID_TO_CLIPBOARD'
                )}
                text={pcpId?.replace(' ', '') ?? ''}
                title="provider-id"
              />
            </Flex>
          </InfoProviderContent>
        ) : null}
        {isOrganization &&
          isHospital &&
          category?.DNT_category?.toLowerCase() ===
            FacilityCategory.HOSPITAL.toLowerCase() && (
            <InfoProviderContent
              icon="task"
              infoTitle={t(
                'PROVIDER_DETAILS.LOCATIONS_TAB.SPECIALTY_INNETWORK'
              )}
              variant="outlined"
            >
              <Flex
                css={{ gap: '4px' }}
                data-testid="sub-heading-speciality-anesthesiolgy"
              >
                <Text css={phoneScreen}>
                  {t('PROVIDER_DETAILS.LOCATIONS_TAB.ANESTHESIOLOGY')} -{' '}
                </Text>
                <Text css={infoTitleContainer(phoneScreen)}>
                  {getYesNoNAText(t, hasAnesthesiology)}
                </Text>
              </Flex>
              <Flex
                css={{ gap: '4px' }}
                data-testid="sub-heading-speciality-emergency-medicine"
              >
                <Text css={phoneScreen}>
                  {t('PROVIDER_DETAILS.LOCATIONS_TAB.EMERGENCY_MEDICINE')} -{' '}
                </Text>
                <Text css={infoTitleContainer(phoneScreen)}>
                  {getYesNoNAText(t, hasEmergencyMedicine)}
                </Text>
              </Flex>
              <Flex
                css={{ gap: '4px' }}
                data-testid="sub-heading-speciality-pathology"
              >
                <Text css={phoneScreen}>
                  {t('PROVIDER_DETAILS.LOCATIONS_TAB.PATHOLOGY')} -{' '}
                </Text>
                <Text css={infoTitleContainer(phoneScreen)}>
                  {getYesNoNAText(t, hasPathology)}
                </Text>
              </Flex>
              <Flex
                css={{ gap: '4px' }}
                data-testid="sub-heading-speciality-radiology"
              >
                <Text css={phoneScreen}>
                  {t('PROVIDER_DETAILS.LOCATIONS_TAB.RADIOLOGY')} -{' '}
                </Text>
                <Text css={infoTitleContainer(phoneScreen)}>
                  {getYesNoNAText(t, hasRadiology)}
                </Text>
              </Flex>
            </InfoProviderContent>
          )}

        {!isCns && isBehavioralHealth && (
          <InfoProviderContent
            icon="speed"
            infoSubtext={getYesNoNAText(t, isExpressAccessProvider)}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.EXPRESS_ACCESS_PROVIDER')}
          />
        )}

        {isOrganization ? (
          <InfoProviderContent
            icon="location_city"
            infoSubtext={providerId}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.FACILITY_ID')}
            variant="outlined"
          >
            <Flex
              css={{
                gap: '$xs',
                '@screen < $sm': { width: 'auto' },
              }}
            >
              <Text
                color="$info1"
                css={infoContainer(phoneScreen)}
                fontWeight="$bold"
              >
                {providerId}
              </Text>
              <CopyToClipBoard
                gap="0"
                label={t(
                  'PROVIDER_LOCATIONS_DETAILS.COPY_FACILITY_ID_TO_CLIPBOARD'
                )}
                text={providerId}
                title="facility-id"
              />
            </Flex>
          </InfoProviderContent>
        ) : null}
      </Flex>
    </React.Fragment>
  );
};
