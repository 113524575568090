import translationsEnJson from '../../../public/locale/en/translation.json';

export const getDistanceFilterOption = (value: number | string) => [
  {
    label: `${value} ${translationsEnJson.miles}`,
    value: Number(value),
    dnt_label: `${value} ${translationsEnJson.miles}`,
    c_value: Number(value),
  },
];
