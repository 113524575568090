import { phoneOnly } from "../../common/ConstantsStyles";
import { useMediaQuery } from "@abyss/web/hooks/useMediaQuery";
import { ConstantsLagoon } from "../../common/ConstantsLagoon";
import { getFeatureFlag } from "../../common/Utils/getFeatureFlag";
import { useLagoon } from "../useLagoon/useLagoon";

export const useSpacing = (
  shouldApplyInSection=false,
) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const isExpandViewAllEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EXPANDED_SEARCH_RADIUS
  );

  let css = {};
  if (isExpandViewAllEnabled && shouldApplyInSection) {
    css = {
      marginTop: mobileScreen ? '32px' : '40px',
    };
  }

  return css;
};
