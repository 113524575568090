import { useContext } from 'react';

import { PortalConfig, PortalUrl } from '../common/Portal';
import { PortalContext } from '../context/PortalContext';

export const getPortalConfigs = (): PortalConfig => {
  const { portalData } = useContext(PortalContext);
  return portalData?.portalConfig || {};
};

export const getPortalConfigUrls = (): PortalUrl[] => {
  const { portalData } = useContext(PortalContext);
  return portalData?.portalConfig?.portalUrls || [];
};

export const portalShouldShowOnlineScheduling = (): boolean => {
  const showOnlineScheduling: any = getPortalConfigs()?.showOnlineScheduling;
  return showOnlineScheduling == false ? false : true;
};

export const portalShouldShowVirtualVisits = (): boolean => {
  const showVirtualVisits: any = getPortalConfigs()?.showVirtualVisits;
  return showVirtualVisits == false ? false : true;
};
