import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { CompareProvider } from '../../../../../../../models/Provider';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../../../utils/providerNames.utils';
import { compareCardRemoveTextStyle } from '../../utility/compareDrawer.styled';

type Props = {
  selectedProvider: CompareProvider;
  removeItem: (locationId: string) => void;
  index: number;
};

export const RemoveLink = ({ selectedProvider, removeItem, index }: Props) => {
  const { t } = useTranslation();
  const nameAttrs = getNameAttrs(selectedProvider);
  return (
    <Flex
      alignItems="center"
      aria-label={`${t('SHARE_DRAWER_TEXT.REMOVE')} ${parseProviderName(
        selectedProvider.providerName,
        selectedProvider.providerType,
        selectedProvider.primaryDegrees?.[0],
        nameAttrs
      )}`}
      css={{
        'abyss-flex-root': {
          marginTop: '12px',
        },
      }}
      data-auto-testid="removed-provider"
      data-testid="removed-provider"
      onClick={() => {
        adobeLinkTrackEvent({
          name: 'remove button',
          location: `modal:compare card:position ${index + 1}`,
          type: 'internal',
        });
        removeItem(selectedProvider.locationId);
      }}
      style={{ gap: '3.5px' }}
    >
      <IconMaterial icon="remove_circle_outline" size="$sm" />
      <Text css={compareCardRemoveTextStyle}>
        {t('SHARE_DRAWER_TEXT.REMOVE')}
      </Text>
    </Flex>
  );
};
