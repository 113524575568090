import { styled } from '@abyss/web/tools/styled';

export const Wrapper = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '$wrapperPadding',

  variants: {
    isWidget: {
      true: {
        padding: '16px 0',
        '@media (min-width: 464px)': {
          marginLeft: '24px',
          marginRight: '24px',
        },
        '@screen < $sm': {
          marginLeft: '16px',
          marginRight: '16px',
        },
      },
    },
    pcpAlert: {
      true: {
        padding: '0px',
      },
    },
  },
  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
});

export const PSXHeaderWrapper = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '$headerWrapperPadding',

  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '0px',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '0px',
    paddingTop: '16px',
    paddingBottom: '0px',
  },

  variants: {
    showSearchInputBackButton: {
      true: {
        '@screen < $md': {
          marginLeft: '24px',
          marginRight: '0px',
          paddingTop: '32px',
          paddingBottom: '0px',
        },
        '@screen < $sm': {
          marginLeft: '16px',
          marginRight: '0px',
          paddingTop: '12px',
          paddingBottom: '0px',
        },
      },
    },
  },
});
