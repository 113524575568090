import isNil from 'lodash/isNil';

import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { convertProviderTypeToAdobeType } from '../Utils/adobeTrackUtils/adobeTrackUtils';

export function setPinOnMapFn(updatePin, practitioner) {
  if (updatePin) {
    updatePin(practitioner);
  }
}

export function inOrOutOfNetwork(status) {
  return status ? 'In-network' : 'Not-In-Network';
}

export function displayTier1Identifier(isTier1Provider) {
  if (isNil(isTier1Provider)) {
    return false;
  }
  return isTier1Provider;
}

export function displayPremiumCareIdentifier(
  premiumCareFeatureFlag,
  isPremiumCarePhysician,
  isTieredProvider,
  memberHasRPK,
  isCustomPreferredPolicy
) {
  if (
    isNil(premiumCareFeatureFlag) ||
    isNil(isPremiumCarePhysician) ||
    isNil(isTieredProvider) ||
    isNil(memberHasRPK) ||
    isNil(isCustomPreferredPolicy)
  ) {
    return false;
  }

  return (
    premiumCareFeatureFlag &&
    isPremiumCarePhysician &&
    !isTieredProvider &&
    !memberHasRPK &&
    !isCustomPreferredPolicy
  );
}

export const getLinkNameForSearchResultEvent = (
  providerTypeInfo?: string,
  clickSection?: string
) => {
  let linkName: string | undefined;

  if (clickSection && clickSection !== '') {
    linkName = clickSection;
  } else {
    linkName = `${convertProviderTypeToAdobeType(providerTypeInfo)} name`;
  }
  return linkName;
};

export const getLinkPositionIndex = (linkPosition?: number) => {
  if (linkPosition !== undefined) {
    return linkPosition + 1;
  }
  return linkPosition;
};

export const handlePatientReviewsView = (
  enableCost,
  practitioner,
  displayVirtualVisit
) => {
  let view = true;
  if (!(enableCost && practitioner?.costIndicator)) {
    view = false;
  }
  if (!(enableCost && practitioner?.costIndicator) && !displayVirtualVisit) {
    view = true;
  }
  if (!displayVirtualVisit) {
    view = true;
  }
  return view;
};

export const getDetailsRoute = (sectionTypeDerived) => {
  let detailsRoute;
  switch (sectionTypeDerived) {
    case Constants.RESULT_SECTION.FACILITY_CAPITAL:
      detailsRoute = ConstantsRoutes.FACILITY_DETAILS.path;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      detailsRoute = ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
      break;
    default:
      detailsRoute = ConstantsRoutes.PROVIDER_DETAILS.path;
      break;
  }
  return detailsRoute;
};
