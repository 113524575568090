import { useQuery } from '@abyss/web/hooks/useQuery';

import MISSING_TRANSLATION_LOG_QUERY from './MissingTranslationLog.graphql';

export const useTranslationLog = (options, headersFromCustomQuery, url) => {
  const [, queryAction] = useQuery(MISSING_TRANSLATION_LOG_QUERY, {
    ...options,
    headers: { ...headersFromCustomQuery },
    url,
    accessor: 'saveMissingTranslation',
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    },
  });

  return queryAction;
};
