import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { abbreviateLocation } from '../../../utils/locationSearch.util';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';

type Props = {
  locationInputVal: any;
  stateCode: any;
  zipCode: any;
  setIsOpenMobileLocation: any;
};
export const MobileSetLocation = ({
  locationInputVal,
  stateCode,
  zipCode,
  setIsOpenMobileLocation,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Layout.Group css={{ paddingBottom: '12px', paddingTop: '8px' }}>
      <Flex alignItems="center">
        <Text
          css={{
            marginTop: '2px',
            color: '#002677',
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '14px',
          }}
        >
          {t('PCP_LOCATION.SET_LOCATION')}
        </Text>
        <IconMaterial
          color="#002677"
          css={{
            'abyss-icon-material': {
              width: '18px',
              height: '18px',
            },
          }}
          icon="location_on"
        />
        <Layout.Stack
          css={{
            marginLeft: '4px',
            color: '$primary2',
          }}
        >
          <LinkWithTracking
            after={
              <IconMaterial
                color="#002677"
                css={{
                  'abyss-icon-material': {
                    width: '18px',
                    height: '18px',
                  },
                }}
                icon="keyboard_arrow_down"
              />
            }
            analyticsInfo={{
              location: 'body:locations',
            }}
            aria-label={`${abbreviateLocation(
              locationInputVal,
              stateCode,
              zipCode
            )}, Set location`}
            css={{ color: '#002677', lineHeight: '18px' }}
            data-auto-testid="user-location-current-location"
            data-testid="user-location-current-location"
            fontWeight="600"
            onClick={() => setIsOpenMobileLocation(true)}
            size="$sm"
            variant="native"
          >
            {abbreviateLocation(locationInputVal, stateCode, zipCode)}
          </LinkWithTracking>
        </Layout.Stack>
      </Flex>
    </Layout.Group>
  );
};
