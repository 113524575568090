import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../common/Constants';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { slideStyle } from '../../../../../common/SnackCard/SnackCard.styles';
import { makeAllSlidesAriaHidden } from '../../../../../common/Utils/a11yUtils/a11yUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
  handleLinkAndModalTrack,
} from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useCoverageType } from '../../../../../hooks/useCoverageType';
import { ResponseHeaders } from '../../../../../models/ResponseHeaders';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { ConfirmationModal } from '../CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import {
  AvatarShareCard,
  BoxStyled,
  ButtonCss,
  LocationIcon,
  PlaceHolderText,
  RemoveButtonIcon,
  RemoveButtonText,
  ShareBoxStyledPlaceHolders,
  ShareBoxTextAddress,
  ShareBoxTextName,
  addressFlexCss,
  carouselStyles,
  slideContainerStyle,
} from './ShareDrawer.styled';

type Props = {
  handleDetailsOnClick: (
    providerId: string,
    options?: Object,
    customAttributesBlock?: CustomAttributesBlock
  ) => void;
  removeItem: (item: string) => void;
  headers: ResponseHeaders;
  selected: number;
  selectedProviderList: {
    providerName: string;
    providerType: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    address: {
      line: string[];
    };
    phones: {
      phone: string[];
    };
    locationId: string;
    imageLocation: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }[];
};

export const ShareDrawerCarousel = ({
  handleDetailsOnClick,
  headers,
  selected,
  selectedProviderList,
  removeItem,
}: Props) => {
  const modal = useOverlay('view-provider-modal');
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>(null);
  const coverageType = useCoverageType();
  const [clickedIndex, setClickedIndex] = useState(0);
  makeAllSlidesAriaHidden();

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(selectedProviderList[clickedIndex], coverageType)
    ),
    providerType: convertProviderTypeToAdobeType(
      selectedProviderList[clickedIndex]?.providerType
    ),
  };

  const handleProviderNameAndCarrotClick = (index) => {
    setClickedIndex(index);
    handleLinkAndModalTrack(
      PRACTITIONER_NAME_FOR_ANALYTICS,
      `modal:share card:position ${clickedIndex}`,
      NEW_TAB_CONFIRMATION,
      undefined,
      customAttributesBlock
    );
    modal?.open();
  };

  const handleClickCb = () => {
    modal.close();
    handleDetailsOnClick(
      selectedProviderList[clickedIndex]?.providerId,
      {
        openInNewTab: true,
        providerType: selectedProviderList[clickedIndex]?.providerType,
      },
      customAttributesBlock
    );
  };

  const carouselSelectedProviderSlides = Array.from(Array(5).keys()).map(
    (i) => (
      <Slide css={slideStyle(i)}>
        <Slide.Container css={slideContainerStyle}>
          <Card
            css={{
              'abyss-card-root': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Card.Section css={{ padding: '5px' }}>
              {selectedProviderList[i] ? (
                <div>
                  <Flex
                    alignItems="center"
                    data-auto-testid="share-provider-card"
                    data-testid="share-provider-card"
                    direction="column"
                  >
                    <BoxStyled
                      align="start"
                      css={{ marginTop: '0px' }}
                      cssProps={{ selected }}
                      data-auto-testid={`share-card-${selectedProviderList[i].providerId}`}
                      data-testid={`share-card-${selectedProviderList[i].providerId}`}
                      key={selectedProviderList[i].locationId}
                    >
                      <AvatarShareCard>
                        {ImageComponent({
                          imgRef,
                          providerType: 'Practitioner',
                          imgSrc: selectedProviderList[i]?.imageLocation,
                        })}
                      </AvatarShareCard>
                      <Flex direction="column">
                        <Tooltip
                          align="center"
                          content={parseProviderName(
                            selectedProviderList[i].providerName,
                            selectedProviderList[i].providerType,
                            selectedProviderList[i].primaryDegrees?.[0],
                            getNameAttrs(selectedProviderList[i])
                          )}
                          position="top"
                          positionOffset={8}
                        >
                          <ShareBoxTextName
                            data-auto-testid="share-provider-name"
                            data-testid="share-provider-name"
                            onClick={() =>
                              handleProviderNameAndCarrotClick(
                                i,
                                selectedProviderList[i].providerType
                              )
                            }
                          >
                            {parseProviderName(
                              selectedProviderList[i].providerName,
                              selectedProviderList[i].providerType,
                              selectedProviderList[i].primaryDegrees?.[0],
                              {
                                firstName: selectedProviderList[i].firstName,
                                middleName: selectedProviderList[i].middleName,
                                lastName: selectedProviderList[i].lastName,
                              }
                            )}
                          </ShareBoxTextName>
                        </Tooltip>
                        <Flex css={addressFlexCss}>
                          <IconMaterial css={LocationIcon} icon="location_on" />
                          <Tooltip
                            align="center"
                            content={t(selectedProviderList[i].address.line[0])}
                            position="top"
                            positionOffset={8}
                          >
                            <ShareBoxTextAddress>
                              {t(selectedProviderList[i].address.line[0])}
                            </ShareBoxTextAddress>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </BoxStyled>
                  </Flex>
                  <Layout.Group css={ButtonCss}>
                    <Button
                      data-auto-testid="button-remove"
                      data-testid="button-remove"
                      onClick={() => {
                        adobeLinkTrackEvent({
                          name: 'remove button',
                          location: `modal:share card:position ${i + 1}`,
                          type: 'internal',
                        });
                        removeItem(selectedProviderList[i].locationId);
                      }}
                      variant="ghost"
                    >
                      <IconMaterial
                        css={RemoveButtonIcon}
                        icon="remove_circle_outline"
                      />
                      <Text css={RemoveButtonText}>
                        {t('SHARE_DRAWER_TEXT.REMOVE')}
                      </Text>
                    </Button>
                  </Layout.Group>
                  <ConfirmationModal
                    closeButtonText={
                      t(
                        'COMPARE_DRAWER_CONFIRMATION_MODAL.VIEW_PROVIDER_BUTTON'
                      ) || ''
                    }
                    dataForAnalytics={{
                      linkName: PRACTITIONER_NAME_FOR_ANALYTICS,
                      linkLocation: `modal:share card:position ${clickedIndex}`,
                      modalName: NEW_TAB_CONFIRMATION,
                      linkType: undefined,
                      customAttributesBlock,
                    }}
                    handleClickCb={() => handleClickCb()}
                    isViewProvider
                    locationForAnalytics={`modal:${NEW_TAB_CONFIRMATION}`}
                    modal={modal}
                    modalName="view-provider-modal"
                  />
                </div>
              ) : (
                <Box
                  css={ShareBoxStyledPlaceHolders}
                  data-auto-testid="share-selections-container"
                  data-testid="share-selections-container"
                  index={i}
                  key={`share-card-skeleton${i}`}
                >
                  <Text
                    css={PlaceHolderText}
                    data-auto-testid="share-drawer-placeholder"
                    data-testid="share-drawer-placeholder"
                  >
                    {t('SHARE_DRAWER_TEXT.CARD_PLACEHOLDER')}
                  </Text>
                </Box>
              )}
            </Card.Section>
          </Card>
        </Slide.Container>
      </Slide>
    )
  );

  return (
    <React.Fragment>
      <Carousel
        css={carouselStyles}
        data-auto-testid="share-drawer-carousel"
        data-testid="share-drawer-carousel"
        minimal
        nextSlideOnClick={() => makeAllSlidesAriaHidden()}
        noLoop
        prevSlideOnClick={() => makeAllSlidesAriaHidden()}
        slideIndexOnClick={() => makeAllSlidesAriaHidden()}
        slides={carouselSelectedProviderSlides}
        slidesPerView={1}
        variant="none"
      />
    </React.Fragment>
  );
};
