import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { portalShouldShowOnlineScheduling } from '../../utils/portalConfig.utils';

export const showUpcomingAvailabilityOption = (
  showUpcomingAvailabilityFF: boolean,
  isPreEffective: boolean,
  upcomingAvailabilityEligible: boolean
): boolean => {
  const showOnlineScheduling = portalShouldShowOnlineScheduling();
  const resultsHasProviderWithUpcomingAvailability = useStore(
    StoreKeys.RESULTS_HAS_PROVIDER_WITH_UPCOMING_AVAILABILITY
  );

  return (
    upcomingAvailabilityEligible &&
    showOnlineScheduling &&
    resultsHasProviderWithUpcomingAvailability &&
    showUpcomingAvailabilityFF &&
    !isPreEffective
  );
};
