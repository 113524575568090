import { CareCategories, ReverseCoverageTypesCodes } from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { getFeatureFlag } from '../common/Utils';
import { useCoverageType } from '../hooks/useCoverageType';
import { useLagoon } from '../hooks/useLagoon';

export const isDentalNetworkIdNotAvailable = (
  selectedChipValue,
  currentMember
): boolean => {
  const coverageType = useCoverageType();

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const showDentalChip: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );

  const dentalNetworkId =
    currentMember?.networks?.filter(
      (coverage) =>
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTAL ||
        ReverseCoverageTypesCodes[coverage?.type] ===
          ReverseCoverageTypesCodes.DENTALEMBEDDED
    )[0]?.value?.[0] || '';

  if (
    coverageType === ReverseCoverageTypesCodes.DENTAL &&
    selectedChipValue === CareCategories.DENTAL &&
    !dentalNetworkId &&
    showDentalChip
  ) {
    return true;
  }

  return false;
};
