import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import { useState } from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ContentWrapper } from '../ContentWrapper';
import { DisclaimerContainer } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { CNSOHDisclaimersContainer } from './CNSOHDisclaimerContainer';
import {
  CNSDisclaimersLinkStyles,
  HealthGradesStyled,
} from './DisclaimersContainer.styled';

interface Props {
  isOHCNS: boolean;
  search?: string;
  sectionType?: string;
}

type Disclaimer = {
  key: '';
  content: '';
};

export const CNSDisclaimersContainer = ({
  isOHCNS,
  search,
  sectionType,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDisclaimer, setSelectedDisclaimer] = useState<Disclaimer>();

  const lagoonData = useLagoon('ui-messaging')();

  const uhcDualCompletePlanDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.UHC_DUAL_COMPLETE_PLAN_DISCLAIMER,
  });

  const uhcConnectedDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.UHC_CONNECTED_DISCLAIMER,
  });

  const findCareDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.FIND_CARE_DISCLAIMER,
  });

  const trackEvents = (isOpen: boolean, title: any): void => {
    if (!isOpen) {
      adobeLinkTrackEvent({ name: title, location: 'footer' });
      adobeModalTrackEvent(title);
    } else {
      adobeLinkTrackEvent({ name: 'close', location: `modal:${title}` });
    }
  };

  return (
    <HealthGradesStyled
      data-auto-testid="standard-disclaimer-content"
      data-testid="standard-disclaimer-content"
    >
      <ContentWrapper>
        <DisclaimerContainer cssProps={{ ratingSection: true }}>
          <Flex css={CNSDisclaimersLinkStyles} direction="column">
            <Link
              data-testid="uhc-dual-complete-plan-disclaimer"
              isStandardAnchor
              onClick={() => {
                setIsOpen(true);
                setSelectedDisclaimer({
                  key: uhcDualCompletePlanDisclaimer?.title,
                  content: uhcDualCompletePlanDisclaimer?.message,
                });
                trackEvents(isOpen, uhcDualCompletePlanDisclaimer?.title);
              }}
            >
              {uhcDualCompletePlanDisclaimer?.title}
            </Link>
            <Link
              data-testid="uhc-connected-disclaimer"
              isStandardAnchor
              onClick={() => {
                setIsOpen(true);
                setSelectedDisclaimer({
                  key: uhcConnectedDisclaimer?.title,
                  content: uhcConnectedDisclaimer?.message,
                });
                trackEvents(isOpen, uhcConnectedDisclaimer?.title);
              }}
            >
              {uhcConnectedDisclaimer?.title}
            </Link>
            <Link
              data-testid="find-care-disclaimer"
              isStandardAnchor
              onClick={() => {
                setIsOpen(true);
                setSelectedDisclaimer({
                  key: findCareDisclaimer?.title,
                  content: findCareDisclaimer?.message,
                });
                trackEvents(isOpen, findCareDisclaimer?.title);
              }}
            >
              {findCareDisclaimer?.title}
            </Link>
          </Flex>
          {isOHCNS ? (
            <CNSOHDisclaimersContainer
              search={search ?? ''}
              sectionType={sectionType}
            />
          ) : null}
        </DisclaimerContainer>
      </ContentWrapper>
      <Modal
        isDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        position="right"
        title={selectedDisclaimer?.key}
      >
        <Modal.Section>
          <Markdown>{selectedDisclaimer?.content}</Markdown>
        </Modal.Section>
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              data-testid="disclaimer-modal-close"
              onClick={() => {
                setIsOpen(false);
                trackEvents(isOpen, selectedDisclaimer?.key);
              }}
            >
              Close
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </HealthGradesStyled>
  );
};
