import { UserDDPCode } from '../../utils/providerDetails.utils';

export interface State {
  sessionId: string;
  planSelector: {
    bigN: {
      DOB: string;
      FirstName: string;
      GroupNumber: string;
      LastName: string;
      MemberNumber: string;
      portalSource: String;
      userName: String;
      id: String;
    };
  };
  memberInfo: any;
  uiState: {
    compareProvidersFlow: {
      indexOflastProviderSelected: number;
    };
  };
  featureSuppressionUIstate: any;
  featureInclusionUIstate: any;
  navigationHistory: {
    previousPageName: string;
    previousPageRank: number;
    currentPageName: string;
    currentPageRank: number;
  };
  hasBreadCrumbClickedFlag: boolean;
  memberSelectionPopupFlag: boolean;
  isTier1Plan: boolean;
  isWidget: boolean;
  coverageType: string;
  userDDPCode: UserDDPCode;
  resultsHasProviderWithUpcomingAvailability: boolean;
}

export const intialUIState = {
  compareProvidersFlow: {
    indexOflastProviderSelected: -1,
    shouldFocusLastIndex: false,
  },
  shareProvidersFlow: {
    indexOflastProviderSelected: -1,
    shouldFocusLastIndex: false,
  },
  hasDismissedLookingForPCP: false,
  hasBreadCrumbClickedFlag: false,
  resultsHasProviderWithUpcomingAvailability: false,
};

export const featureSuppressionUIState = {};
export const featureInclusionUIState = {};

export enum StoreKeys {
  SESSION_ID = 'sessionId',
  SET_SESSION_ID = 'setSessionId',
  PLAN_SELECTOR = 'planSelector',
  SET_PLAN_SELECTOR = 'setPlanSelector',
  OBAPI_MEMBERS = 'memberInfo',
  SET_OBAPI_MEMBERS = 'setMemberInfo',
  SET_UI_STATE = 'setUiState',
  UI_STATE = 'uiState',
  UI_FEATURE_SUPPRESSION = 'featureSuppressionUIstate',
  SET_UI_FEATURE_SUPPRESSION = 'setfeatureSuppressionUIstate',
  UI_FEATURE_INCLUSION = 'featureInclusionUIstate',
  SET_UI_FEATURE_INCLUSION = 'setfeatureInclusionUIstate',
  SET_NAVIGATION_HISTORY = 'setNavigationHistory',
  NAVIGATION_HISTORY = 'navigationHistory',
  BREADCRUMB_CLICKED_FLAG = 'hasBreadCrumbClickedFlag',
  SET_BREADCRUMB_CLICKED_FLAG = 'setHasBreadCrumbClickedFlag',
  MEMBER_SELECTION_POPUP_FLAG = 'memberSelectionPopupFlag',
  SET_MEMBER_SELECTION_POPUP_FLAG = 'setMemberSelectionPopupFlag',
  TIER1_PLAN_FLAG = 'isTier1Plan',
  SET_TIER1_PLAN_FLAG = 'setIsTier1Plan',
  IS_WIDGET = 'isWidget',
  SET_IS_WIDGET = 'setIsWidget',
  COVERAGE_TYPE = 'coverageType',
  SET_COVERAGE_TYPE = 'setCoverageType',
  SET_PRE_PROD_TOGGLE = 'setEnablePreProd',
  USER_DDP_CODE = 'userDDPCode',
  SET_USER_DDP_CODE = 'setUserDDPCode',
  RESULTS_HAS_PROVIDER_WITH_UPCOMING_AVAILABILITY = 'resultsHasProviderWithUpcomingAvailability',
  SET_RESULTS_HAS_PROVIDER_WITH_UPCOMING_AVAILABILITY = 'setResultsHasProviderWithUpcomingAvailability'
}
