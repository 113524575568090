import { useRouter } from '@abyss/web/hooks/useRouter';
import { ProviderSearchForPortal } from 'parcels/src/frontends/ProviderSearchForPortal/ProviderSearchForPortal';
import React from 'react';

import { PSX_LANDING_PAGE_PARAMS } from '../../parcels/src/common/Constants';
import { PortalData } from '../../parcels/src/common/Portal';
import {
  mockGuestPortalMemberInfo,
  mockTier1PortalMemberInfo,
} from '../../parcels/src/common/Portal/mockGuestPortalMemberInfo';
import { mockPortalMemberInfo } from '../../parcels/src/common/Portal/mockPortalMemberInfo';
import { ProviderSearch } from '../../parcels/src/frontends/ProviderSearch';
import { DetailsDrawer } from './DetailsDrawer';

export const browser = () => {
  const { getLocation } = useRouter();
  const { pathname = '' } = getLocation() || {};
  if (pathname === '/details-widget') {
    return <DetailsDrawer />;
  }
  if (pathname.startsWith('/laww')) {
    // Parse the current URL
    const urlParams = new URLSearchParams(window.location.search);

    // Get language, defaults to en-US
    const lang = urlParams.get('lang') || 'en-US';

    // Get the user tier, defaults to 2
    const userTier = urlParams.get('userTier') || '2';
    const eapOnlyClient = urlParams.get('eapOnlyClient') === 'true';
    const showEapFilter =
      eapOnlyClient || urlParams.get('showEapFilter') !== 'false';
    const lockEapFilter =
      eapOnlyClient || urlParams.get('lockEapFilter') === 'true';
    const preSelectEapFilter =
      eapOnlyClient || urlParams.get('preSelectEapFilter') === 'true';
    const showOnlineScheduling =
      urlParams.get('showOnlineScheduling') !== 'false';
    const portalShowCost = !(
      eapOnlyClient || urlParams.get('showCost') === 'false'
    );
    const showTreatmentCenter = !(
      eapOnlyClient || urlParams.get('showTreatmentCenter') === 'false'
    );
    const memberInfo = () => {
      if (userTier === '0') return mockGuestPortalMemberInfo;
      if (userTier === '1') return mockTier1PortalMemberInfo;
      return mockPortalMemberInfo;
    };
    const showVirtualVisits = urlParams.get('showVirtualVisits') !== 'false';

    const portalData: PortalData = {
      portalName: 'LAWW',
      portalConfig: {
        basePath: '/laww',
        headerChipsEnabled: false,
        theme: 'optum',
        language: lang,
        homePage: PSX_LANDING_PAGE_PARAMS,
        signInUrl: '/api/auth/server-signin?callbackUrl=LANDING_PAGE',
        isGuestUser: userTier === '0',
        showCost: portalShowCost,
        showTreatmentCenter,
        includeBaseCss: true,
        userTier,
        showEapFilter,
        lockEapFilter,
        preSelectEapFilter,
        showVirtualVisits,
        showOnlineScheduling,
      },
      portalMemberInfo: memberInfo(),
      portalUrls: [],
    };

    return <ProviderSearchForPortal portal-data={JSON.stringify(portalData)} />;
  }

  return <ProviderSearch />;
};
