import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { ConstantsLagoon } from '../ConstantsLagoon';
import {
  carouselMinScreen,
  carouselScreen,
  phoneOnly,
} from '../ConstantsStyles';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { SnackDetails, SnackInfoCard } from '../SnackCard/SnackCard.styles';
import { getFeatureFlag } from '../Utils';

type SnackCardViewAllLinkProps = {
  heading?: string;
  handleViewAllOnClick?: () => void;
  providerType?: string;
  isVirtualCareOffered?: boolean;
  hasPhysicalAddress?: boolean;
  isNetworkAlertIndicator?: boolean;
  isInNetworkBadge?: boolean;
  isOutOfCntyIndicatorEnabled?: boolean;
  isCaraousel?: boolean;
};

export const SnackCardViewAllLink = ({
  heading,
  handleViewAllOnClick,
  providerType,
  isVirtualCareOffered,
  hasPhysicalAddress,
  isNetworkAlertIndicator,
  isInNetworkBadge,
  isOutOfCntyIndicatorEnabled = false,
  isCaraousel = false,
}: SnackCardViewAllLinkProps) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );
  const infoCardHeight = (
    isNetworkAlertIndicator,
    isInNetworkBadge,
    displayInNetworkBadgeEnabled
  ) =>
    isNetworkAlertIndicator ||
    (isInNetworkBadge && displayInNetworkBadgeEnabled)
      ? {
          height: '235px',
          '@screen < $sm': { height: '220px' },
        }
      : '';

  const mobileScreen = useMediaQuery(phoneOnly);
  const carouselBreakPoint = useMediaQuery(carouselScreen);
  const carouselMinBreakPoint = useMediaQuery(carouselMinScreen);

  const { isExpandViewAllEnabled } = useSnackcardViewAllCount();

  const getHeight = () => {
    let css = {};
    const displayVirtualProviderGroup =
      isVirtualCareOffered &&
      !hasPhysicalAddress &&
      providerType === 'Medical Group';

    if (displayVirtualProviderGroup) {
      css = { height: '201px' };
    }

    if (carouselBreakPoint && isOutOfCntyIndicatorEnabled) {
      css = { marginBottom: '25px' };
    }
    if (
      (carouselBreakPoint && isOutOfCntyIndicatorEnabled && isCaraousel) ||
      (mobileScreen && isOutOfCntyIndicatorEnabled)
    ) {
      css = { marginBottom: '34px' };
    }
    if (carouselBreakPoint && !isOutOfCntyIndicatorEnabled && isCaraousel) {
      css = { marginBottom: '25px' };
    }
    if (carouselBreakPoint && !isOutOfCntyIndicatorEnabled && !isCaraousel) {
      css = { marginBottom: '2px' };
    }

    if (carouselMinBreakPoint && isOutOfCntyIndicatorEnabled) {
      css = { marginBottom: '26px' };
    }

    css = {
      ...css,
      ...infoCardHeight(
        isNetworkAlertIndicator,
        isInNetworkBadge,
        displayInNetworkBadgeEnabled
      ),
    };

    return css;
  };

  const getEllipsisText = (text: string) =>
    text?.length > 35 ? `${text.slice(0, 35)}...` : text;

  return (
    <SnackInfoCard
      alignContent="center"
      alignItems="center"
      css={{
        ...{
          justifyContent: 'center',
          width: 'min-content',
        },
        ...getHeight(),
      }}
      data-auto-testid={`snack-card-${heading}`.replace(/ /g, '-')}
      data-testid={`snack-card-${heading}`.replace(/ /g, '-')}
      direction="column"
    >
      <SnackDetails
        alignContent="center"
        alignItems="center"
        direction="column"
      >
        <Tooltip
          align="center"
          asChild={false}
          content={`${t('All')} ${heading?.toLowerCase()}`}
          position="top"
        >
          <LinkWithTracking
            after={
              <IconMaterial
                icon="chevron_right"
                size={(isExpandViewAllEnabled && '$md') || '$xs'}
              />
            }
            analyticsInfo={{
              location: `body:${heading} results`,
            }}
            css={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: mobileScreen ? '138px' : '162px',
              '&:hover': { borderColor: 'transparent' },
              '& .abyss-insert-element-root': {
                justifyContent: 'flex-start',
              },
            }}
            data-auto-testid={`cta-snackcard-${heading}`}
            data-testid={`cta-snackcard-${heading}`}
            isStandardAnchor
            onClick={handleViewAllOnClick}
          >
            <Text
              color="$interactive1"
              css={{
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordWrap: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
              fontWeight="$bold"
            >
              {getEllipsisText(`${t('All')} ${heading?.toLowerCase()}`)}
            </Text>
          </LinkWithTracking>
        </Tooltip>
      </SnackDetails>
    </SnackInfoCard>
  );
};
