import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Modal } from '@abyss/web/ui/Modal';

export const LocationsDrawerHeaderMobile = styled('div', {
  width: '279px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
  color: '#323334',
  fontFamily: '$primary',
});

export const FlexContainer = styled('p', {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '12px',
  flexBasis: '50%',
  marginBottom: '0px',
});

export const TierProvider = styled('div', {
  background: '#F2F8E6',
  padding: '2px $sm',
  borderRadius: '4px',
});

export const TierProviderText = styled('div', {
  padding: '2px $sm',
  borderRadius: '4px',
});

export const IconMaterialContainer = styled(IconMaterial, {
  marginTop: '3px',
});

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  border: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '#196ECF',
  minHeight: 'unset',
  padding: '0',
  width: '230px',
  '.abyss-card-root': {
    width: '215px',
    gap: '0px',
  },
  '.abyss-card-title': {
    margin: '12px 0px 0  30px',
    fontSize: '16px',
    fontWeight: '$bold',
    lineHeight: '20px',
    height: '20px',
    gap: '2px',
    justifyContent: 'unset',
  },
  '.abyss-card-collapse-button-container': {
    marginLeft: '0px',
    padding: '0px',
  },
  '.abyss-card-collapse-button': {
    width: '24px',
    span: {
      padding: '0px',
      gap: '0px',
      width: '24px',
      marginRight: '0px',
    },
  },
};

export const CollapseCardSectionStyle = {
  color: '#4B4D4F',
  padding: '0',
  borderTop: 'none !important',
};

export const IconMaterialStyle = {
  width: '18px',
  height: '18px',
  marginRight: '7px',
  marginLeft: '27px',
  '@screen < $sm': {
    width: '13.5px',
    height: '13.5px',
    marginRight: '6.25px',
  },
};

export const InfoAlignmentContainer = styled(Flex, {
  flexDirection: 'column',
  width: 'fit-content',
});

export const InfoWebAlignmentContainer = styled(Flex, {
  width: '50%',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  '@screen < $md': {
    width: '100%',
  },
});

export const EveningAppointemnt = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$neutralGray7',
  width: 'fit-content',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const MilesContent = styled('span', {
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '20px',
  alignItems: 'center',
  textAlign: 'center',
  color: '$interactive1',
  '@screen < $sm': {
    height: '20px',
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const PhoneNumberContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '18px',
  lineHeight: '24px',
  width: '149.5px',
  height: '24px',
  color: '$interactive1',
});

export const CollapsePhoneNumberContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '16px',
  lineHeight: '20px',
  width: '149.5px',
  color: '$interactive1',
});

export const CollapsePhoneContainer = styled('div', {
  fontWeight: '$semibold',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  marginLeft: '12px',
});

export const infoTitleContainer = (phoneScreen: boolean) => ({
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$neutralGray7',
  ...(phoneScreen && {
    fontSize: '$md',
    lineHeight: '$md',
  }),
});

export const infoSubTextContainer = (phoneScreen: boolean) => ({
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '#4B4D4F',
  ...(phoneScreen && {
    fontSize: '$md',
    lineHeight: '$md',
  }),
});

export const InfoContainer = styled('div', {
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$info1',
  '@screen < $sm': {
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const infoContainer = (phoneScreen: boolean) => ({
  display: 'block',
  fontSize: '$lg',
  lineHeight: '$lg',
  ...(phoneScreen && {
    fontSize: '$md',
    lineHeight: '$md',
    fontWeight: 'bold',
  }),
});

export const PhoneContainer = styled('span', {
  height: '48px',
  fontWeight: '$semibold',
  fontSize: '18px',
  lineHeight: '24px',
  color: '#4B4D4F',
  marginLeft: '12px',
  '@screen < $sm': {
    lineHeight: '20px',
    fontSize: '16px',
  },
});

export const PhoneAllContainer = styled('div', {
  display: 'flex',
  marginTop: '$sm',
  '@screen < $md': {
    marginRight: 0,
  },
});

export const PhoneFlexContainer = styled('p', {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '32px',
  marginBottom: '$lg',
});

export const timeContainer = (phoneScreen: boolean) => ({
  marginLeft: '3px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$gray7',
  ...(phoneScreen && {
    fontSize: '$md',
    lineHeight: '20px',
  }),
});

export const AlertSectionContainer = styled('div', {
  borderWidth: '1px 0',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderColor: '$cardBorder',
  marginBottom: '8px',
  padding: '16px calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '16px $lg',
  },
  '@screen < $md': {
    padding: '16px $md',
  },
});

export const SectionExpertiseContainer = styled('div', {
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  flexBasis: '50%',
  '@screen < $sm': {
    padding: '$md',
  },
});

export const NetworkStartDateCard = styled('div', {
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '$lg',
  color: '$gray7',
  marginTop: '$lg',
  '@screen <= $sm': {
    fontSize: '$md',
    lineHeight: '$md',
    marginTop: '$xl',
    height: '40px',
  },
});

export const ProviderNetworkStartDateSection = styled('div', {
  fontWeight: '$light',
  paddingBottom: '$md',
});

export const InfoProviderContentContainer = styled(Flex, {
  flexWrap: 'wrap',
  width: '100%',
  rowGap: '$lg',
  paddingLeft: 'calc((100% - 1200px) / 2)',
  paddingRight: 'calc((100% - 1200px) / 2)',
  '@screen < $md': {
    flexDirection: 'column',
    width: 'unset',
    rowGap: '20px',
    padding: '0',
  },
});

export const infoProviderContentContainer = (mobileScreen: boolean) => ({
  flexWrap: 'wrap',
  width: '100%',
  rowGap: '$lg',
  paddingLeft: 'calc((100% - 1200px) / 2)',
  paddingRight: 'calc((100% - 1200px) / 2)',
  ...(mobileScreen && {
    flexDirection: 'column',
    width: 'unset',
    rowGap: '20px',
    padding: '0',
  }),
});

export const ProviderGroupContainer = styled('div', {
  color: '#196ECF',
  marginTop: '3px',
  fontSize: '18px',
  fontWeight: '$bold',
  lineHeight: '24px',
  alignItems: 'center',
  textAlign: 'center',
  '@screen < $sm': {
    fontSize: '16px',
    lineHeight: '20px',
    margin: '0',
  },
});

export const Heading = styled('div', {
  width: '338px',
  height: '32px',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '$primary1',
  marginBottom: '12px',
  '@screen < $sm': {
    height: '24px',
    fontSize: '20.25px',
    lineHeight: '24px',
    marginBottom: 'initial',
  },
});

export const VoiceOnlyText = styled('span', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});

export const Dot = styled('div', {
  height: '5px',
  width: '5px',
  backgroundColor: '$gray7',
  borderRadius: '15px',
});

export const PopoverModalContainer = styled(Modal, {
  '.abyss-modal-close-button': {
    padding: 0,
    minHeight: '24px',
    minWidth: '24px ',
  },
  '.abyss-modal-body': {
    gap: '10px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#4B4D4F',
    borderTop: '1px solid white',
    button: {
      height: '40px',
      width: 'auto',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
    },
  },
});

export const isModalContainerDrawer = {
  'abyss-modal-content-container': {
    borderRadius: '4px 4px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    padding: '0 $md $md $md',
    color: '$gray8',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  'abyss-modal-close-button': {
    padding: 0,
    minHeight: '24px',
    minWidth: '24px ',
  },
  'abyss-modal-content-title': {
    fontSize: '32.43px !important',
    fontWeight: '700 !important',
    lineHeight: '40px !important',
    alignItems: 'center',
    color: '#002677 !important',
  },
  'abyss-modal-header-container': {
    padding: '$sm $md',
  },
};

export const buttonStyled = {
  padding: '$sm $md $sm $md',
  width: '73px',
  border: 0,
  '@screen < $sm': {
    padding: '10px $lg 10px $lg',
    height: '48px',
    width: '89px',
  },
  '.abyss-button-content-container': {
    lineHeight: '20px',
    width: '41px',
  },
};
export const ButtonContainer = styled('div', {
  padding: '0 $md $md 0',
});
export const ContentContainer = styled('div', {
  padding: '$md',
});

export const iconStyle = {
  marginTop: '2px',
  '@screen < $sm': { marginTop: '0' },
};
