import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import isArray from 'lodash/isArray';
import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Option, ProviderSearchFilters } from '../../models/ProviderSearch';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchFilterEvent } from '../AdobeTagging/adobeSearchFilterEvent';
import { CheckBoxGroup } from '../CheckBoxGroup';
import { Constants, FilterOptionsType } from '../Constants';
import { RadioGroup } from '../RadioGroup';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  getFormattedFilters,
  getSearchTerm,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { FilterSlider } from './FilterSlider';

type Props = {
  title: string;
  options: Option[];
  model: string;
  type: string;
  label: string;
  defaultValue: any;
  onApplyFilter: (selectedFilters: {}) => void;
  clearFilter: (model: string) => void;
  onFilterSelectionChange: (
    data: ProviderSearchFilters,
    lastModifiedModel: string
  ) => void;
  onPopoverDismiss: () => void;
  totalCount: number;
  isLoading: boolean;
};

// To bring popover over map and other content
export const FilterPopOverWrapper = styled('div', {
  '.abyss-popover-trigger ~ div': {
    zIndex: '999 !important',
  },
  '.abyss-popover-trigger': {
    borderRadius: '24px',
  },
});

export const FilterToggleButton = styled('div', {
  height: '40px',
  fontSize: '16px',
  padding: '20px',
  border: '1px solid $gray4',
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
});

export const NameFilter = ({
  title = '',
  options = [],
  model = '',
  type = '',
  label = '',
  defaultValue,
  onApplyFilter,
  clearFilter,
  onFilterSelectionChange,
  onPopoverDismiss,
  totalCount = 0,
  isLoading,
}: Props) => {
  const [showFilter, setShowFilter] = useState(false);

  const { t } = useTranslation();
  const isShowSelected = isArray(defaultValue)
    ? defaultValue?.length && !defaultValue?.[0].hide
    : defaultValue?.value;

  const chipsFilters: string = Constants.PROVIDER_RESULTS.FILTER_BY;

  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      searchMethod: state.typeaheadSearchStore.searchMethod,
      searchTerm: state.typeaheadSearchStore.searchTerm,
      virtualCare: state.typeaheadSearchStore.virtualCare,
      gender: state.typeaheadSearchStore.gender,
      specialtyCategory: state.typeaheadSearchStore.specialtyCategory,
      condition: state.typeaheadSearchStore.condition,
    }))
  );
  const handleApply = (data) => {
    setShowFilter(false);
    let selectedFilter;

    if (type === FilterOptionsType.RADIO) {
      selectedFilter = {
        [model]: options.filter((option) => data === option.value.toString()),
      };
    } else if (type === FilterOptionsType.SLIDER) {
      selectedFilter = {
        [model]: options.filter((option) => data === option.c_value),
      };
    } else {
      selectedFilter = {
        [model]: options.filter((option) =>
          data.includes(option.value.toString())
        ),
      };
    }
    onApplyFilter(selectedFilter);
    adobeSearchFilterEvent({
      filters: getFormattedFilters(selectedFilter),
      term: getSearchTerm(typeaheadState),
    });
  };

  const onOptionChange = (e: { [key: string]: Option[] }) => {
    const lastModifiedModel =
      Object.values(e)[0].length > 0 ? Object.keys(e)[0] : '';
    onFilterSelectionChange(e, lastModifiedModel);
  };

  const handleOnClearFilter = (event) => {
    clearFilter(model);
    event.stopPropagation();
    adobeLinkTrackEvent({
      name: `${label}:clear`,
      location: `body:${chipsFilters}`,
    });
  };

  const onOpenAnalyticsHandler = () => {
    const linkName: string = label;
    const linkLocation: string = `body:${chipsFilters}`;
    const modalName: string = `${label} filter`;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
  };

  const getDefaultValue = (defaultValue: any) => {
    if (isArray(defaultValue)) {
      return defaultValue.length
        ? defaultValue.map((item: Option) => item.value)
        : [];
    }

    return [defaultValue?.value];
  };

  const selectedOptions = getDefaultValue(defaultValue).length;

  const renderFilterOptions = () => {
    const submitBtnName = !isLoading
      ? t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.SHOW_RESULT_BUTTON').replace(
          '$count',
          totalCount?.toString()
        )
      : t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.LOADING');
    const disableSubmitBtn = isLoading || totalCount === 0;
    switch (type) {
      case FilterOptionsType.RADIO:
        return (
          <RadioGroup
            data-auto-testid={`${model}-chip-filter`}
            data-testid={`${model}-chip-filter`}
            defaultValue={defaultValue?.length ? defaultValue[0].value : ''}
            disableSubmitBtn={disableSubmitBtn}
            locationForAnalytics={`modal:${label} filter`}
            model={model}
            onApply={(data) => {
              handleApply(data);
            }}
            onOptionChange={onOptionChange}
            options={options}
            submitBtnName={submitBtnName}
          />
        );
      case FilterOptionsType.CHECK_BOX:
        return (
          <CheckBoxGroup
            data-auto-testid={`${model}-chip-filter`}
            data-testid={`${model}-chip-filter`}
            defaultValue={getDefaultValue(defaultValue)}
            disableSubmitBtn={disableSubmitBtn}
            locationForAnalytics={`modal:${label} filter`}
            model={model}
            onApply={(data) => {
              handleApply(data);
            }}
            onOptionChange={onOptionChange}
            options={options}
            submitBtnName={submitBtnName}
          />
        );
      case FilterOptionsType.SLIDER:
        return (
          <FilterSlider
            data-auto-testid={`${model}-slider-filter`}
            data-testid={`${model}-slider-filter`}
            defaultValue={defaultValue?.length ? defaultValue[0].c_value : 0}
            disableSubmitBtn={disableSubmitBtn}
            locationForAnalytics={`modal:${label} filter`}
            model={model}
            onApply={(data) => {
              handleApply(data);
            }}
            onOptionChange={onOptionChange}
            options={options}
            submitBtnName={submitBtnName}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FilterPopOverWrapper>
      <Popover
        content={renderFilterOptions()}
        css={{
          'abyss-popover-content': {
            display: 'flex',
            border: 'none',
            borderRadius: '12px',
            padding: '24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '20px',
            alignSelf: 'stretch',
          },
          'abyss-popover-header': {
            height: '32px',
            marginLeft: '4px',
            marginBottom: '20px',
            padding: '7px 0px',
            width: '327px',
          },
          'abyss-popover-close-button': {
            top: '35px',
            right: '24px',
            position: 'absolute',
          },
          'abyss-popover-title': {
            marginTop: '0',
            fontSize: '$largeText !important',
            fontWeight: '$bold !important',
            lineHeight: '28px !important',
          },
        }}
        data-testid={`${label.replace(/ /g, '-').toLocaleLowerCase()}`}
        onOpenChange={() => {
          const isCurrentlyShowing = showFilter;
          setShowFilter(!showFilter);
          if (isCurrentlyShowing) {
            onPopoverDismiss();
          } else {
            onOpenAnalyticsHandler();
          }
        }}
        open={showFilter}
        position="bottom"
        title={title}
        width={375}
      >
        <FilterToggleButton
          data-auto-testid={`${label
            .replace(/ /g, '-')
            .toLocaleLowerCase()}-toggle-filter-button`}
          data-testid={`${label
            .replace(/ /g, '-')
            .toLocaleLowerCase()}-toggle-filter-button`}
          style={{
            background: isShowSelected ? '#CCF2F7' : 'unset',
          }}
        >
          <Text fontWeight="$semibold" size="$md">
            {label}{' '}
            {isShowSelected && selectedOptions > 0
              ? `(${selectedOptions})`
              : ''}
          </Text>
          <IconMaterial
            color="$descriptionBlack"
            icon={showFilter ? 'expand_less' : 'expand_more'}
          />
          {isShowSelected ? (
            <IconMaterial
              color="$descriptionBlack"
              data-auto-testid={`${model}-clear-filter`}
              data-testid={`${model}-clear-filter`}
              icon="close"
              onClick={handleOnClearFilter}
            />
          ) : null}
        </FilterToggleButton>
      </Popover>
    </FilterPopOverWrapper>
  );
};
