import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';

import { LOB } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  getLanguageFromCookie,
  getLobFromCookie,
} from '../Internationalization/helpers';
import { Locale } from '../Internationalization/types';
import {
  languageNotSupportedModalInfoStyles,
  languageNotSupportedModalStyles,
  languageNotSupportedModalTitleStyles,
} from './LanguageNotSupported.styled';

export const LanguageNotSupportedModal = () => {
  const [lob, setLob] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [languageModalStatus, setLanguageModalStatus] = useState(false);
  const data = useLagoon('ui-messaging')();
  const languageModalTitle = find(data, {
    key: ConstantsLagoon.LANGUAGE_MODAL.LANGUAGE_NOT_AVAILABLE,
  });
  const languageModalContent = find(data, {
    key: ConstantsLagoon.LANGUAGE_MODAL.LANGUAGE_NOT_AVAILABLE_CONTENT,
  });
  const viewIdCardButton = find(data, {
    key: ConstantsLagoon.UI_MESSAGING.VIEW_ID_CARD_BUTTON,
  });

  const handleViewIdCard = () => {
    window.location.href =
      '/community/dashboard/modal/id-cards?goBackOnClose=1';
  };

  useEffect(() => {
    const language = getLanguageFromCookie();
    const lobFromCookie = getLobFromCookie() || '';
    setLob(lobFromCookie.toUpperCase());
    setLanguageModalStatus(
      language === Locale.enUS || language === Locale.esUS
    );
  }, []);

  if (languageModalStatus === false && lob === LOB.CNS) {
    return (
      <React.Fragment>
        <Modal
          css={languageNotSupportedModalStyles}
          data-auto-testid="language-not-supported-content-modal"
          data-testid="language-not-supported-content-modal"
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Layout.Stack css={{ alignContent: 'center' }} space={15}>
            <Layout.Stack css={{ alignContent: 'center', paddingTop: '40px' }}>
              <IconBrand
                css={{ margin: '0px' }}
                icon="document_multiple"
                size="70px"
              />{' '}
              <Heading css={languageNotSupportedModalTitleStyles}>
                {languageModalTitle?.message}
              </Heading>
              <Modal.Section
                css={languageNotSupportedModalInfoStyles}
                data-auto-testid="language-not-supported-content-info"
                data-testid="language-not-supported-content-info"
              >
                {languageModalContent?.message}
              </Modal.Section>
            </Layout.Stack>
            <Modal.Section
              css={{
                paddingBottom: '40px',
                '@screen < $md': {
                  paddingBottom: '20px',
                },
              }}
            >
              <Button
                css={{
                  width: '138px',
                  height: '48px',
                }}
                data-auto-testid="language-not-supported-view-id-button"
                data-testid="language-not-supported-view-id-button"
                onClick={handleViewIdCard}
                size="$sm"
              >
                {viewIdCardButton?.message}
              </Button>
            </Modal.Section>
          </Layout.Stack>
        </Modal>
      </React.Fragment>
    );
  }
  return null;
};
